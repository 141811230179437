import React from 'react'
import PropTypes from "prop-types";
import {Link, NavLink} from 'react-router-dom'
import Logo from './logo2'
const NAV_LINKS = [
  { text: 'Login', href: '/login' },
]
function Header({ title, showLoginLink }) {
	return (
		<header className="relative">
			<div className="w-full max-w-6xl px-6 mx-auto">
				<div className="relative flex items-center justify-end" >
					<h1 className="m-0 text-xl font-bold leading-none uppercase">
						<Link to="/" className="flex items-center no-underline">
							<Logo className="mr-2" /> {title}
						</Link>
					</h1>

          {showLoginLink && (<ul
            className="inline-flex justify-center flex-none w-full mb-6 lg:flex lg:w-1/2 lg:justify-end lg:order-1 lg:mb-0">
            {NAV_LINKS.map(({text, href}) => (
              <li key={text} className="ml-4">
                <NavLink to={href} className={(navData)=>
                  navData.isActive ? "is-active": ""
                }>
                  {text}
                </NavLink>
              </li>
            ))}
          </ul>)}
				</div>
			</div>
		</header>
	)
}

Header.defaultProps = {
  title: "",
  showLoginLink: false
}

Header.propTypes = {
  title: PropTypes.string,
  showLoginLink: PropTypes.bool,
}

export default Header
