//credit: https://codesandbox.io/s/63dtc?file=/src/DatePicker.tsx
import React from "react";
import {Days, days, months, Months} from "../../../utils/calendar-constants";

const initState = {
  isOpen: false,
  date: "",
  displayDate: "",
  month: null,
  year: null,
  daysInMonthArr: [],
  blankDaysArr: []
};

const datePickerReducer = (state, action) => {
  switch (action.type) {
    case "SET_INIT_STATE": {
      const today = new Date();
      let month = today.getMonth();
      let year = today.getFullYear();

      let displayDate = "";
      if (state.date) {
        const preselectedDate = new Date(`${state.date}T00:00:00`);
        month = preselectedDate.getMonth()
        year = preselectedDate.getFullYear()
        displayDate = getDate(preselectedDate)
      }

      const dayOfWeek = new Date(year, month).getDay();
      const Weekday = Days[dayOfWeek].CalendarWeekdayNumber;

      const date = state.date || ""//formatYearsMonthDay(new Date(year, month, today.getDate()));

      // Get last day number of the previous actual month
      const daysInMonth = new Date(year, month+1, 0).getDate();

      // Get the number (0-6) on which the actual month starts
      let blankDaysArr= [];
      for (let i = 1; i <= Weekday; i++) {
        blankDaysArr.push(i);
      }

      let daysInMonthArr = [];
      for (let i = 1; i <= daysInMonth; i++) {
        daysInMonthArr.push(i);
      }

      return {
        ...state,
        date,
        displayDate,
        month,
        year,
        daysInMonthArr,
        blankDaysArr
      };
    }

    case "IS_OPEN": {
      return {
        ...state,
        isOpen: action.isOpen
      };
    }

    case "SET_DATE": {
      const dateToFormat = new Date(state.year, state.month, action.dayNumber);
      const date = formatYearsMonthDay(dateToFormat);
      const displayDate = getDate(dateToFormat);

      return {
        ...state,
        date,
        unixTimestamp: dateToFormat.getTime()/1000|0,
        jsonDate: dateToFormat.toJSON(),
        displayDate,
        isOpen: false
      };
    }

    case "ADD_MONTH": {
      let newYear;
      let newMonth;
      if (state.month === 11) {
        newMonth = 0;
        newYear = state.year + 1;
      } else {
        newMonth = state.month + 1;
        newYear = state.year;
      }

      const newMonthFirstWeekdayNumber = new Date(
        newYear,
        newMonth,
        1
      ).getDay();
      const FirstWeekdayNumber =
        Days[newMonthFirstWeekdayNumber].CalendarWeekdayNumber;
      const daysInMonth = new Date(newYear, newMonth + 1, 0).getDate();

      let blankDaysArr = [];
      for (let i = 1; i <= FirstWeekdayNumber; i++) {
        blankDaysArr.push(i);
      }

      let daysInMonthArr = [];
      for (let i = 1; i <= daysInMonth; i++) {
        daysInMonthArr.push(i);
      }

      return {
        ...state,
        month: newMonth,
        year: newYear,
        daysInMonthArr,
        blankDaysArr
      };
    }

    case "SUBTRACT_MONTH": {
      let newYear;
      let newMonth;
      if (state.month === 0) {
        newMonth = 11;
        newYear = state.year - 1;
      } else {
        newMonth = state.month - 1;
        newYear = state.year;
      }

      const newMonthFirstWeekdayNumber = new Date(
        newYear,
        newMonth,
        1
      ).getDay();
      const FirstWeekdayNumber =
        Days[newMonthFirstWeekdayNumber].CalendarWeekdayNumber;
      const daysInMonth = new Date(newYear, newMonth + 1, 0).getDate();

      let blankDaysArr = [];
      for (let i = 1; i <= FirstWeekdayNumber; i++) {
        blankDaysArr.push(i);
      }

      let daysInMonthArr = [];
      for (let i = 1; i <= daysInMonth; i++) {
        daysInMonthArr.push(i);
      }

      return {
        ...state,
        year: newYear,
        month: newMonth,
        daysInMonthArr,
        blankDaysArr
      };
    }

    default: {
      throw Error("Error un reducer");
    }
  }
};

const getDate = (date) => {
  const year = date.getFullYear();
  const monthShortName = Months[date.getMonth()].shortName;
  const day = ("0" + date.getDate()).slice(-2);
  const dayShortName = Days[date.getDay()].shortName;

  return `${dayShortName} ${day} ${monthShortName}, ${year}`;
};

const formatYearsMonthDay = (date) => {
  return (
    date.getFullYear() +
    "-" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + date.getDate()).slice(-2)
  );
};

const DatePicker = ({value, onSelect, errorMessage, reset, required}) => {
  const [state, dispatch] = React.useReducer(datePickerReducer, {...initState, date:value, displayDate:value});
  const displayDateRef = React.useRef();
  const daysDivRef = React.useRef();

  React.useEffect(() => {
    dispatch({ type: "SET_INIT_STATE" });
  }, []);

  React.useEffect(() => {
    dispatch({ type: "SET_INIT_STATE" });
  }, [reset]);

  React.useEffect(() => {
    onSelect({date: state.date, jsonDate: state.jsonDate, unixTimestamp: state.unixTimestamp})
  }, [state.date]);

  const isToday = (dayNumber) => {
    const today = new Date();
    const day = new Date(state.year, state.month, dayNumber);

    return today.toDateString() === day.toDateString() ? true : false;
  };

  const isSelectedDay = (dayNumber) => {
    const day = formatYearsMonthDay(new Date(state.year, state.month, dayNumber));
    return state.date === day ? true : false;
  };

  const isPastDay = (dayNumber) => {
    const today = new Date();
    today.setHours(0,0,0,0);
    const day = new Date(state.year, state.month, dayNumber);

    return day < today ? true : false;
  };

  const handleDatePickerKeydown = (event) => {
    if (event.charCode === 0) {
      dispatch({ type: "IS_OPEN", isOpen: false });
    }
  };

  const toggleDisplayDateFocus = () => {
    /**
     * This functions triggers when the user clicks:
     * 1. The input element
     * 2. The input element goes out of focus
     * 3. A day in the calendar
     *
     * When the calendar input contains shadow-outline class it means it's focus,
     * so we remove that class and trigger blur programatically.
     * On the other hand if the input doesn't have the class, it means it's not focused,
     * so we trigger the focus and add the class.
     */
    const displayDate = displayDateRef.current;
    if (displayDate.classList.contains("shadow-outline")) {
      displayDate.classList.remove("shadow-outline");
      displayDate.blur();
    } else {
      displayDate.classList.add("shadow-outline");
      displayDate.focus();
    }

    const daysDiv = daysDivRef.current;
    daysDiv.focus();
  };

  return (
          <div className="w-full z-auto">
            <div className="relative">
              <input
                type="text"
                readOnly
                value={state.displayDate}
                ref={displayDateRef}
                onClick={() => {
                  dispatch({ type: "IS_OPEN", isOpen: !state.isOpen });
                  toggleDisplayDateFocus();
                }}
                onKeyDown={event => handleDatePickerKeydown(event)}
                onBlur={() => {
                  dispatch({ type: "IS_OPEN", isOpen: false });
                  toggleDisplayDateFocus();
                }}
                className="w-full px-4 py-3 text-sm text-gray-500 bg-white border border-gray-300 rounded-sm shadow-none placeholder-italic"
                placeholder={`Select date${!!required ? "*": ""}`}
              />
              {errorMessage && <div className="text-red-500 text-xs">{errorMessage}</div>}

              <div className="absolute top-0 right-0 px-3 py-2">
                <svg
                  className="h-6 w-6 text-gray-400"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                  />
                </svg>
              </div>

              <div
                className={`focus:outline-none duration-200 mt-12 bg-white rounded-lg shadow p-4 absolute top-0 left-0 ${
                  !state.isOpen ? "invisible opacity-0" : "visible opacity-100"
                }`}
                style={{ width: "17rem" }}
                ref={daysDivRef}
                tabIndex={-1}
              >
                <div className="flex justify-between items-center mb-2">
                  <div>
                    <span className="text-lg font-bold text-gray-800">
                      {months[state.month]}
                    </span>
                    <span className="ml-1 text-lg text-gray-600 font-normal">
                      {state.year}
                    </span>
                  </div>
                  <div>
                    <button
                      type="button"
                      className={`transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-200 p-1 rounded-full focus:shadow-outline focus:outline-none mr-1`}
                      onMouseDown={event => event.preventDefault()}
                      onClick={() => dispatch({ type: "SUBTRACT_MONTH" })}
                    >
                      <svg
                        className="h-6 w-6 text-gray-500 inline-flex"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M15 19l-7-7 7-7"
                        />
                      </svg>
                    </button>
                    <button
                      type="button"
                      onMouseDown={event => event.preventDefault()}
                      className={`transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-200 p-1 rounded-full focus:shadow-outline focus:outline-none`}
                      onClick={() => dispatch({ type: "ADD_MONTH" })}
                    >
                      <svg
                        className="h-6 w-6 text-gray-500 inline-flex"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M9 5l7 7-7 7"
                        />
                      </svg>
                    </button>
                  </div>
                </div>

                <div className="flex flex-wrap mb-3 -mx-1">
                  {days.map((day, index) => (
                    <div
                      key={index}
                      style={{ width: "14.26%" }}
                      className="px-1"
                    >
                      <div className="text-gray-800 font-medium text-center text-xs">
                        {day}
                      </div>
                    </div>
                  ))}
                </div>

                <div className="flex flex-wrap -mx-1">
                  {state.blankDaysArr.map(day => (
                    <div
                      key={day}
                      style={{ width: "14.28%" }}
                      className="text-center border p-1 border-transparent text-sm"
                    />
                  ))}
                  {state.daysInMonthArr.map((dayNumber, index) => {
                    let dayStyle;
                    const isPassedDay = isPastDay(dayNumber);
                    if(isPassedDay) {
                      dayStyle = "bg-gray-300"
                    } else if(isSelectedDay(dayNumber)) {
                      dayStyle = "bg-orange-500 text-white";
                    } else if (isToday(dayNumber)) {
                      dayStyle = "bg-blue-500 text-white";
                    } else {
                      dayStyle = "text-gray-700 hover:bg-blue-200"
                    }
                    return (
                      <div
                        key={index}
                        style={{width: "14.28%"}}
                        className="px-1 mb-1"
                      >
                        <div
                          onClick={() => {
                            if (!isPassedDay) {
                              dispatch({type: "SET_DATE", dayNumber});
                              toggleDisplayDateFocus();
                            }
                          }}
                          onMouseDown={event => event.preventDefault()}
                          className={`cursor-pointer text-center text-sm leading-none rounded-full leading-loose transition ease-in-out duration-100
                                                ${dayStyle}`}
                        >
                          {dayNumber}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
  );
};

export default DatePicker;
