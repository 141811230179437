export const EMAIL_PATTERN = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

export const eventOptions = [
  { value: 'party', label: 'Party' },
  { value: 'auction', label: 'Auction' },
  { value: 'other', label: 'Other' },
]

export const timeOptions = [
  { value: '12:00 pm', label: '12:00 pm'},
  { value: '01:00 pm', label: '01:00 pm'},
  { value: '02:00 pm', label: '02:00 pm'},
  { value: '03:00 pm', label: '03:00 pm'},
  { value: '04:00 pm', label: '04:00 pm'},
  { value: '05:00 pm', label: '05:00 pm'},
  { value: '06:00 pm', label: '06:00 pm'},
  { value: '07:00 pm', label: '07:00 pm'},
  { value: '08:00 pm', label: '08:00 pm'},
  { value: '09:00 pm', label: '09:00 pm'},
  { value: '10:00 pm', label: '10:00 pm'},
  { value: '11:00 pm', label: '11:00 pm'},
  { value: '12:00 am', label: '12:00 am'},
  { value: '01:00 am', label: '01:00 am'},
  { value: '02:00 am', label: '02:00 am'},
  { value: '03:00 am', label: '03:00 am'},
  { value: '04:00 am', label: '04:00 am'},
  { value: '05:00 am', label: '05:00 am'},
  { value: '06:00 am', label: '06:00 am'},
  { value: '07:00 am', label: '07:00 am'},
  { value: '08:00 am', label: '08:00 am'},
  { value: '09:00 am', label: '09:00 am'},
  { value: '10:00 am', label: '10:00 am'},
  { value: '11:00 am', label: '11:00 am'},
]

export const eventStatus = {
  STARTED: "started",
  ENDED: "ended",
  LEFT_EVENT: "left_event",
  LEAVE_EVENT: "leave_event",
  JOINED: "joined",
  INVITED: "invited",
  ACTIVE: "active",
  INACTIVE: "inactive",
  INLOBBY: "inLobby"
  
}

export const modalID = {
 
  LEAVE_EVENT: "leave_event",
  END_EVENT: "end_event",
  CHANGE_DISPLAY_NAME: "change_display_name",
  SHARE: "share",

}