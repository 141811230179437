import { initializeApp } from 'firebase/app'
import React, { useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { AuthProvider } from './components/auth/auth-provider'
import RequireAuth from './components/auth/require-auth'
import Logo from './components/layout/logo2'
import SearchBar from './components/layout/search'
import Sidebar from './components/sidebar/sidebar'
import firebaseConfig from './firebase-exports'
import IndexPage from './pages'
import ContactPage from './pages/contact'
import FaqsPage from './pages/faqs'
import ForgetPassword from './pages/forget-password'
import JoinEvent from './pages/join-event'
import Login from './pages/login'
import NotFound from './pages/not-found'
import PasswordReset from './pages/password-reset'
import Home from './pages/protected/home'
import VerifyEmail from './pages/protected/verify-email'
import SignUp from './pages/sign-up'
import SupportPage from './pages/support'
import TermsAndConditions from './pages/terms-and-conditions'
import OngoingEvent from './pages/protected/event-ongoing'
import EventsOngoingSidebar from './components/sidebar/events-ongoing-sidebar'
import AlertTemplate from './components/alert-template/alert-template'
import store from './store/store'
import { useState } from '@hookstate/core'
import Spinner from './components/spinner'
import Footer from './components/layout/footer'

const {
	initializeAppCheck,
	ReCaptchaEnterpriseProvider,
} = require('firebase/app-check')
console.log(process.env.REACT_APP_CONTEXT)
const app = initializeApp(firebaseConfig[process.env.REACT_APP_CONTEXT])

const DefaultLayout = ({ children }) => {
	return (
		<>
			<Sidebar />
			<div className="relative md:ml-64 bg-blueGray-100">
				{/* Header */}
				<div className="w-full flex  bg-white">
					<div className="w-full flex justify-start">
						<SearchBar />
					</div>
					<div className="w-full flex justify-end">
						<Logo />
					</div>
				</div>
				<div className="mx-auto w-full">
					{children}
					<Footer />
				</div>
			</div>
		</>
	)
}

const OngoingEventLayout = ({ children }) => {
	return (
		<>
			<EventsOngoingSidebar />
			<div className="relative md:ml-72 bg-blueGray-100">
				{/* Header */}
				<div className="w-full flex  bg-white"></div>
				<div className="mx-auto w-full">
					{children}
					{/* Footer */}
				</div>
			</div>
		</>
	)
}

const App = () => {
	const { alert, isLoading } = useState(store)
	const alertData = alert.data.get()
	const toggleAlert = alert.show.get()

	const location = useLocation()
	let state = location.state

	useEffect(() => {
		if (toggleAlert === true) {
			setTimeout(function () {
				alert.set({ show: false })
			}, 3000)
		}
	}, [toggleAlert, alertData])

	useEffect(() => {
		initializeAppCheck(app, {
			provider: new ReCaptchaEnterpriseProvider(
				process.env.REACT_APP_RECAPTCHA_ID
			),
			isTokenAutoRefreshEnabled: true, // Set to true to allow auto-refresh.
		})
	}, [])
	return (
		<AuthProvider>
			<AlertTemplate
				show={toggleAlert}
				message={alertData?.message}
				type={alertData?.type}
			/>
			<Spinner loading={isLoading.get()} />
			<Routes location={state?.backgroundLocation || location}>
				<Route path="/" element={<IndexPage />} />
				<Route path="/faqs" element={<FaqsPage />} />
				<Route path="/support" element={<SupportPage />} />
				<Route path="/contact" element={<ContactPage />} />
				<Route path="/login" element={<Login />} />
				<Route path="/signUp" element={<SignUp />} />
				<Route path="/join/:eventId" element={<JoinEvent />} />
				<Route path="/TermsAndConditions" element={<TermsAndConditions />} />
				<Route path="/verifyemail" element={<VerifyEmail />} />
				<Route path="/passwordreset" element={<PasswordReset />} />

				<Route
					path="/home"
					element={
						<RequireAuth>
							<DefaultLayout>
								<Home />
							</DefaultLayout>
						</RequireAuth>
					}
				/>

				<Route
					path="/events/:eventId"
					element={
						<RequireAuth>
							<OngoingEventLayout>
								<OngoingEvent />
							</OngoingEventLayout>
						</RequireAuth>
					}
				/>
				<Route path="*" element={<NotFound />} />
			</Routes>

			{state?.backgroundLocation && (
				<Routes>
					<Route path="/forgetPassword" element={<ForgetPassword />} />

					<Route path="/signUp" element={<SignUp />} />
				</Routes>
			)}
		</AuthProvider>
	)
}

export default App
