import React from 'react'

const Loading = ({loading}) => {
  return (
    <>
      {loading &&
      (<div className="flex justify-center items-center">
        <div className="
            spinner-border
            border-orange-500
            animate-spin
            inline-block
            w-8
            h-8
            border-4
            rounded-full
            text-purple-500
          " role="status">
          <span className="invisible">Loading...</span>
        </div>
      </div>)
      }
    </>
  )
}

export default Loading
