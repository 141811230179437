import { getFunctions, httpsCallable } from 'firebase/functions'
import { removeEmptyOrNullValues } from '../utils/service-utils'
import store from '../store/store'
import { retrieveEvent } from './events'
import { getDatabase, onValue, ref, update } from "firebase/database";
import {deSanitizedEmail, sanitizedEmail} from '../utils/email-util';



export const batchSubscribe = (data, onSuccess) => {
	const subscribe = httpsCallable(
		getFunctions(),
		'subscriptions-batchSubscribe'
	)

	// remove empty fields with empty strings
	removeEmptyOrNullValues(data)

	subscribe(data)
		.then((result) => {
			// Read result of the Cloud Function.
			/** @type {any} */
			const data = result.data
			if (onSuccess) {
				onSuccess(data)
			}
		})
		.catch((error) => {
			// Getting the Error details.
			const code = error.code
			const message = error.message
			const details = error.details
			console.log(code, message, details)
		})
}
export const subscribeToEvent = (data, onSuccess, onFailure) => {
	const subscribeToEvent = httpsCallable(
		getFunctions(),
		'subscriptions-subscribeToEvent'
	)
	subscribeToEvent(data)
		.then((result) => {
			// Read result of the Cloud Function.
			/** @type {any} */
			const data = result.data
			if (onSuccess) {
				onSuccess(data)
			}
		})
		.catch((error) => {
			// Getting the Error details.
			const message = error.message
			onFailure(message)
		})
}

export const batchSubscribeToEvent = (data, onSuccess, onFailure) => {
	const batchSubscribe = httpsCallable(
		getFunctions(),
		'subscriptions-batchSubscribe'
	)
	batchSubscribe(data)
		.then((result) => {
			// Read result of the Cloud Function.
			/** @type {any} */
			const data = result.data
			if (onSuccess) {
				onSuccess(data)
			}
		})
		.catch((error) => {
			// Getting the Error details.
			const message = error.message
			if (onFailure) {
				onFailure(message)
			}
		})
}

export const retrieveSubscriptions = (options, onSuccess, onFailure) => {
	const retrieveSubscription = httpsCallable(
		getFunctions(),
		'subscriptions-retrieveSubscriptions'
	)

	retrieveSubscription({options})
		.then((result) => {
			// Read result of the Cloud Function.
			/** @type {any} */
			const data = result.data
			//store.events.merge(data)
			//let activeEvents = []
			for (let d = 0; d < data.length; d++) {
				retrieveEvent(data[d].eventId, (eventObject) => {
					if (eventObject.status !== 'inactive') {
						//activeEvents.push(eventObject)

						store.events.merge([eventObject])
					}
				})
				//break
			}

			if (onSuccess) {
				onSuccess()
			}
		})
		.catch((error) => {
			// Getting the Error details.
			const message = error.message
			if (onFailure) {
				onFailure(message)
			}
		})
}

export const updateSubscription = (data, onSuccess, onFailure) => {
	const update = httpsCallable(
		getFunctions(),
		'subscriptions-updateEventSubscription'
	)

	update(data)
		.then((result) => {
			const data = result.data
			const { event, events } = store
			events[event.selectedEvent.get()].merge(data)
			if (onSuccess) {
				onSuccess(data)
			}
		})
		.catch((error) => {
			// Getting the Error details.
			const message = error.message
			if (onFailure) {
				onFailure(message)
			}
		})
}


export const fetchParticipant =  (eventId) => {
	const db = getDatabase();


    const query = ref(db, `eventSubscriptions/${eventId}`);
    return onValue(query, (snapshot) => {
      const data = snapshot.val();
      const arr = []
      if (snapshot.exists()) {
        console.log(data)
        Object.keys(data).forEach(k => {
          const v = data[k]
          arr.push({email: deSanitizedEmail(k), ...v})
        });
      }
      console.log('**', arr)
	  store.participants.set(arr)

    });

  }

  export const fetchEventByUserEmail = (eventId, userEmail) => {
	const db = getDatabase();
    const query = ref(db, `eventSubscriptions/${eventId}/${sanitizedEmail(userEmail)}`);
    return onValue(query, (snapshot) => {

      if (snapshot.exists()) {
        const data = snapshot.val();
		store.eventDetail.set({email:userEmail, ...data})
      }
    });

  }

  export const updateSubscriptionInRealTimeDatabase = (eventId, userEmail, data) => {
	store.modal.set({open: false})
    store.isLoading.set(true)
    
	const db = getDatabase();
	return update(ref(db, `eventSubscriptions/${eventId}/${sanitizedEmail(userEmail)}`), data)

  }

