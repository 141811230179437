import * as Yup from "yup";
import {EMAIL_PATTERN} from "../constants";

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
// const meetingUrlRexExp = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%\/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/;

export const validationSchema = Yup.object().shape({
  email: Yup.string().required("Email is required").email("Email is invalid"),
  phone: Yup.string().required("Phone number is required").matches(phoneRegExp, 'Phone number is not valid'),
  eventAccess: Yup.string().required("Accessibility is required"),
  eventDetails: Yup.string(),
  date: Yup.string().required("Date is required"),
  eventName: Yup.string().required("Event name is required")
    .test('len', 'Event name cannot be more than 50 characters long', (val = "") => val.length < 50),
  eventType: Yup.string().required("Event type is required"),
  eventLocation: Yup.string().required("Event Location is required"),
  // locationDetails: Yup.string().required("End Time is required"),
  eventMode: Yup.string().required("Event Mode is required"),
  meetingUrl: Yup.string(),
  street: Yup.string(),
  city: Yup.string(),
  state: Yup.string(),
  zipcode: Yup.string(),
  country: Yup.string()
});

export const getChangedValues = (values, initialValues) => {
  return Object
    .entries(values)
    .reduce((acc, [key, value]) => {
      const hasChanged = initialValues[key] !== value

      if (hasChanged) {
        acc[key] = value
      }

      return acc
    }, {})
}

export const validateEmail = (email) => {
  const valid = email.length > 0 &&  EMAIL_PATTERN.test(email)
  console.log("valid", valid, EMAIL_PATTERN.test(email), email)
  let errorMessage = ''
  if (!valid) {
    errorMessage= `${email} is not a valid email address`
  }
  return {valid, errorMessage}
}

export const displayNameSchema = Yup.object().shape({
  displayName: Yup.string().required("display name is required"),

});
