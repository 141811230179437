import React from "react";
import Modal from "./modal";
import {GrClose} from "react-icons/gr";
import Button from "../forms/button/button";
import {useNavigate} from "react-router-dom";
import {BsCheckCircleFill} from "react-icons/bs";
import {ImWarning} from "react-icons/im";

function ConfirmationModal({opened, text, title, actionStatus, enableAction, disableAction}) {
  const navigate = useNavigate();
  const actionButtons =
    <div className="control flex justify-between">
      <Button
        onClick={() => {
          enableAction()
        }}
        label="Yes"
      />
      <Button
      customStyle={"ml-10"}
        onClick={() => {
          disableAction()
        }}
        label="No"
      />
    </div>

  const header = (
    <div className="p-6 w-full flex items-center justify-center text-5xl bg-orange-400 text-white">
      {actionStatus ? <BsCheckCircleFill/> : <ImWarning/>}
    </div>
  )
  return <Modal
    header={header}
    actionButtons={actionButtons}
    opened={opened}
    icon={<GrClose/>}>
    <div className="grid grid-columns-1 md:grid-rows-1 gap-3 text-center">
      <div className="bg-white w-full font-bold text-orange-400 text-3xl">{title}</div>
      <div className="text-gray-400">{text}</div>
    </div>
  </Modal>;
}

ConfirmationModal.propTypes = {};

ConfirmationModal.defaultProps = {
  title: "",
  opened: false,
  onClose() {
  },
  enableAction(){},
  disableAction(){},
  text: "",
  actionStatus: false
}

export default ConfirmationModal
