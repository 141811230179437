import React from 'react'
import Layout from '../layouts/index'
import {
	Article,
	ArticleContent,
	ArticleMedia,
} from '../components/layout/article'

const ContactPage = () => {
	return (
		<Layout>
			<Article>
				<ArticleContent title="Contact">
					<p>
						You can reach out to us on
						<a href="mailto:email@example.com"> innovations@dynamocash.com</a>
					</p>
				</ArticleContent>

				<ArticleMedia>
					<img src={`${process.env.PUBLIC_URL}/contact_us.jpg`} alt="Contact Us" />
				</ArticleMedia>
			</Article>
		</Layout>
	)
}

export default ContactPage
