import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'

export default function RequireAuth({ children, ...rest }) {
	const { isAuthenticated, anon, emailVerified } = JSON.parse(
		localStorage.getItem('user')
	)

	let location = useLocation()

	try {
		//TODO: if not verfied got navigate to page
		if (!isAuthenticated || anon) {
			return <Navigate to="/login" state={{ from: location }} />
		}
		if (emailVerified === false) {
			return <Navigate to="/verifyemail" state={{ from: location }} />
		}

		return children
	} catch (e) {
		console.log(e)
		return null
	}
}
