export const months = {
  0: "January",
  1: "February",
  2: "March",
  3: "April",
  4: "May",
  5: "June",
  6: "July",
  7: "August",
  8: "September",
  9: "October",
  10: "November",
  11: "December"
};

export const Months = {
  0: {
    name: "January",
    shortName: "Jan",
    CalendarMonthNumber: 1
  },
  1: {
    name: "February",
    shortName: "Feb",
    CalendarMonthNumber: 2
  },
  2: {
    name: "March",
    shortName: "Mar",
    CalendarMonthNumber: 3
  },
  3: {
    name: "April",
    shortName: "Apr",
    CalendarMonthNumber: 4
  },
  4: {
    name: "May",
    shortName: "May",
    CalendarMonthNumber: 5
  },
  5: {
    name: "June",
    shortName: "Jun",
    CalendarMonthNumber: 6
  },
  6: {
    name: "July",
    shortName: "Jul",
    CalendarMonthNumber: 7
  },
  7: {
    name: "August",
    shortName: "August",
    CalendarMonthNumber: 8
  },
  8: {
    name: "September",
    shortName: "Sep",
    CalendarMonthNumber: 9
  },
  9: {
    name: "October",
    shortName: "Oct",
    CalendarMonthNumber: 10
  },
  10: {
    name: "November",
    shortName: "Nov",
    CalendarMonthNumber: 11
  },
  11: {
    name: "December",
    shortName: "Dec",
    CalendarMonthNumber: 12
  }
};

export const Days = {
  0: {
    name: "Sunday",
    shortName: "Sun",
    CalendarWeekdayNumber: 6
  },
  1: {
    name: "Monday",
    shortName: "Mon",
    CalendarWeekdayNumber: 0
  },
  2: {
    name: "Tuesday",
    shortName: "Tue",
    CalendarWeekdayNumber: 1
  },
  3: {
    name: "Wednesday",
    shortName: "Wed",
    CalendarWeekdayNumber: 2
  },
  4: {
    name: "Thursday",
    shortName: "Thu",
    CalendarWeekdayNumber: 3
  },
  5: {
    name: "Friday",
    shortName: "Fri",
    CalendarWeekdayNumber: 4
  },
  6: {
    name: "Saturday",
    shortName: "Sat",
    CalendarWeekdayNumber: 5
  }
};

export const days = ["Mun", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
