import React from 'react'
import PropTypes from 'prop-types'

const AlertModal = ({
                 icon,
                 children,
                 opened,
                 onClose,
                 actionButtons,
                  header
               }) => {
  // const open = useState(opened)
  return (
    <>
      {opened ? (
        <>
          <div
            className="absolute flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-gray-600 bg-opacity-50"
          >
            <div className="relative w-3/4 md:w-1/2 my-6 mx-auto ">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative grid grid-col-1 gap-3 w-full bg-white outline-none focus:outline-none ">
               
                  <div onClick={() => {
                  onClose()
                }} className="absolute top-5 right-3 text-xl invisible md:visible">
                    {icon}
                  </div>
               
               
                  <div className=" text-blueGray-500 text-lg leading-relaxed">
                    {children}
                  </div>
                
              
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>)
}

AlertModal.defaultProps = {
  title: "",
  children: null,
  opened: false,
  onClose() {
  },
  onSubmit() {
  },
  allowSubmit: true,
  cancelText: "Close",
  submitText: "Save Changes",
  showActionButtons: true
}

AlertModal.propTypes = {
  title: PropTypes.string,
  children: PropTypes.object,
  opened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  allowSubmit: PropTypes.bool,
  showActionButtons: PropTypes.bool
}

export default AlertModal
