import React from 'react'
import Layout from '../layouts/index'
import {Article, ArticleContent} from "../components/layout/article";

export default function NotFound({title, msg}) {
  let titleString = title
  let messageString = msg
  if (!title) {
    titleString = "Page Not Found"
  }

  if (!msg) {
    messageString = "We cannot find the page you are requesting for!"
  }


  return (
    <Layout>
      <Article>
        <ArticleContent title={titleString}>
          <p>{messageString}</p>
        </ArticleContent>
      </Article>
    </Layout>
  )
}
