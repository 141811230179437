import React from 'react'
import Modal from './modal'
import { GrClose } from 'react-icons/gr'
import Button from '../forms/button/button'
import { BsCheckCircleFill } from 'react-icons/bs'
import { ImWarning } from 'react-icons/im'
import { useState } from '@hookstate/core'
import store from '../../store/store'
const DeleteModal = ({ opened, text, title, actionStatus, handleDelete }) => {
	const { isEventDeleted } = useState(store)
  const { close } = useState(false)

	const actionButtons = (
		<div className="#">
			<div className="flex space-x-5 ...">
				<div className="text-base">
					<Button
						bgColor="bg-secondary-400"
						onClick={() => {
              handleDelete()
              close.set(true)
            }}
						label="Yes"
					/>
				</div>
				<div className="text-base px-6">
					<Button
						// className="bg-gray-900 text-white"
						bgColor="bg-gray-500"
						onClick={() => {
							isEventDeleted.set(false)
						}}
						label="No"
					/>
				</div>
			</div>
		</div>
	)

	const header = (
		<div className="p-6 w-full flex items-center justify-center text-5xl bg-orange-400 text-white">
			{actionStatus ? <BsCheckCircleFill /> : <ImWarning />}
		</div>
	)
	return (
		<Modal
			header={header}
			actionButtons={actionButtons}
			opened={close?.get() || opened}
			icon={<GrClose />}
		>
			<div className="grid grid-columns-1 md:grid-rows-1 gap-3 text-center">
				<div className="bg-white w-full font-bold text-orange-400 text-3xl">
					{title}
				</div>
				<div className="text-gray-400">{text}</div>
			</div>
		</Modal>
	)
}

DeleteModal.propTypes = {}

DeleteModal.defaultProps = {
	title: '',
	opened: false,
	onClose() {},
	text: '',
	actionStatus: false,
}

export default DeleteModal
