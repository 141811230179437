import {allTimezones} from "react-timezone-select";
import moment from "moment-timezone";

export const  changeTimeZone = (date, timeZone) => {
  if (typeof date === 'string') {
    return new Date(
      new Date(date).toLocaleString('en-US', {
        timeZone,
      }),
    );
  }

  return new Date(
    date.toLocaleString('en-US', {
      timeZone,
    }),
  );
}

export const dateWithTimeZone = (timeZone, year, month, day, hour=0, minute=0, second=0) => {
  let date = new Date(Date.UTC(year, month-1, day, hour, minute, second)); //month is zero based

  let utcDate = new Date(date.toLocaleString('en-US', { timeZone: "UTC" }));
  let tzDate = new Date(date.toLocaleString('en-US', { timeZone: timeZone }));
  let offset = utcDate.getTime() - tzDate.getTime();

  date.setTime( date.getTime() + offset );

  return date;
};

export const convertTime12to24 = (time12h) => {
  if (!time12h) {
    return time12h;
  }

  const [time, modifier] = time12h.split(' ');

  let [hours, minutes] = time.split(':');

  if (hours === '12') {
    hours = '00';
  }

  if (modifier === 'PM') {
    hours = parseInt(hours, 10) + 12;
  }

  return {hours, minutes};
}

export const formatMinuteTime = (minutes) => {
  let h = Math.floor(minutes / 60);
  const m = Math.floor(minutes % 60);
  h = new Intl.NumberFormat("en-US", {
    signDisplay: "always"
  }).format(h);
  return [
    h,
    m > 9 ? m : (h ? '0' + m : m || '0')
  ].filter(Boolean).join(':');
}

export const getTime = (time) => {
  var minute = ''
  var timeArray = ''
 if (time) {
  timeArray = time.split(":");

  if (timeArray.length && timeArray[1]) {

    minute = timeArray[1].split(" ")

  }
 }
 return { hour:timeArray[0], minute: minute[0], meridiem: minute[1]  }
}

export const getCurrentTimezoneName = () => {
  const today = new Date();
  const short = today.toLocaleDateString(undefined);
  const full = today.toLocaleDateString(undefined, { timeZoneName: 'long' });

  // Trying to remove date from the string in a locale-agnostic way
  const shortIndex = full.indexOf(short);
  if (shortIndex >= 0) {
    const trimmed = full.substring(0, shortIndex) + full.substring(shortIndex + short.length);

    // by this time `trimmed` should be the timezone's name with some punctuation -
    // trim it from both sides
    return trimmed.replace(/^[\s,.\-:;]+|[\s,.\-:;]+$/g, '');

  } else {
    // in some magic case when short representation of date is not present in the long one, just return the long one as a fallback, since it should contain the timezone's name
    return full;
  }
}

const tz = Intl.DateTimeFormat().resolvedOptions().timeZone

let timezoneOptions = Object.keys(allTimezones).map( k => ({label: `(UTC${formatMinuteTime(moment.tz(k).utcOffset())}) ${allTimezones[k]}`, value: k}))
export const currentTimeZoneOption = {label: `(UTC${formatMinuteTime(moment.tz(tz).utcOffset())}) ${getCurrentTimezoneName()}`, value: tz}
timezoneOptions = [currentTimeZoneOption,...timezoneOptions]
export const getTimezoneOptions = () => timezoneOptions
