import {timeOptions} from "../../../constants";
import React from "react";
import PropTypes from "prop-types";
import Dropdown from "./select";
import {getTimezoneOptions} from "../../../utils/date-utils";

const timezoneOptions = getTimezoneOptions()

const TimeSelect = ({ readOnly, timeLabel, timezoneLabel, errorMessage, onTimeChange, onTimezoneChange, timePlaceholder, timezonePlaceholder, selectedTimeOption, selectedTimezoneOption }) => {
  const textSize =  readOnly ? "text-2xl" : "text-sm"
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-2 my-2">

      <div className="font-bold text-orange-400 ">{timeLabel}</div>
      <div className="font-bold text-orange-400 ">{timezoneLabel}</div>
      <div className={`${textSize} text-black`}>
        <div className="w-40">
          <div className="flex">
            <Dropdown
              readOnly={readOnly}
              selectedOption={selectedTimeOption}
              errorMessage={errorMessage?.time}
              placeholder={timePlaceholder}
              options={timeOptions}
              onChange={onTimeChange}
            />
          </div>
        </div>
      </div>
      <div className={`${textSize} text-black`}>
        <div className="w-40">
          <div className="flex">
            <Dropdown
              readOnly={readOnly}
              selectedOption={selectedTimezoneOption}
              errorMessage={errorMessage?.timezone}
              placeholder={timezonePlaceholder}
              options={timezoneOptions}
              onChange={onTimezoneChange}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

TimeSelect.defaultProps = {
  selectedTimeOption: null,
  selectedTimezoneOption: null,
  timePlaceholder: '',
  timezonePlaceholder: '',
  onTimezoneChange() {
  },
  onTimeChange() {
  },
  errorMessage: {},
  readOnly: false
}

TimeSelect.propTypes = {
  selectedTimeOption: PropTypes.shape({label: PropTypes.string, value: PropTypes.string}),
  selectedTimezoneOption: PropTypes.shape({label: PropTypes.string, value: PropTypes.string}),
  timePlaceholder: PropTypes.string,
  timezonePlaceholder: PropTypes.string,
  onTimeChange: PropTypes.func,
  onTimezoneChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({label: PropTypes.string, value: PropTypes.string})),
  errorMessage: PropTypes.shape({time: PropTypes.string, timezone: PropTypes.string}),
  readOnly: PropTypes.bool
}

export default TimeSelect
