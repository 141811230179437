import {customStyles} from "../../../utils/select-util";
import Select from 'react-select'
import PropTypes from "prop-types";
import React from "react";

const Dropdown = ({readOnly, errorMessage, onChange, options, placeholder, selectedOption}) => {
  if (readOnly) {
    return (
      <>
        {selectedOption?.value}
      </>
    )
  }

  return (
    <div className="w-full">
      <Select
        value={selectedOption}
        placeholder={placeholder}
        options={options}
        styles={customStyles()}
        onChange={onChange}
      />

      {errorMessage && (
        <div className="text-red-500 text-xs">
          {errorMessage}
        </div>
      )}
    </div>
  )
}

Dropdown.defaultProps = {
  selectedOption: null,
  placeholder: '',
  onChange() {
  },
  options: [],
  errorMessage: '',
  readOnly: false
}

Dropdown.propTypes = {
  selectedOption: PropTypes.shape({label: PropTypes.string, value: PropTypes.string}),
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({label: PropTypes.string, value: PropTypes.string})),
  errorMessage: PropTypes.string,
  name: PropTypes.string,
  readOnly: PropTypes.bool
}

export default Dropdown;
