import React, {useEffect, useRef} from 'react'
import {IoChevronBack, IoChevronForward} from "react-icons/io5";
import {useState} from "@hookstate/core";
import {copyBlobToClipboard, getBlobFromImageElement} from "copy-image-clipboard";
import store from "../../store/store";

const QrcodeImageSlider = () => {
  const slideRef = useRef();
  const qRCopied = useState(false)
  const {event, events} = useState(store)
  const eventz = events.get()
  const selectedEvent = event.selectedEvent.get()
  const count = useState(0);

  useEffect(() => {
    slideRef.current?.addEventListener("animationend", removeAnimation);
  }, [])

  useEffect(() => {
    const timeId = setTimeout(() => {
      // After 3 seconds set the show value to false
      qRCopied.set(false)
    }, 3000)

    return () => {
      clearTimeout(timeId)
    }
  }, [qRCopied]);

  const removeAnimation = () => {
    slideRef.current?.classList.remove("fade-anim");
  };

  const copyQrCode = () => {
    const imageElement = document.getElementById('qrcode')
    getBlobFromImageElement(imageElement)
      .then((blob) => {
        return copyBlobToClipboard(blob)
      })
      .then(() => {
        console.log('Blob Copied')
        qRCopied.set(true)
      })
      .catch((e) => {
        console.log('Error: ', e.message)
      })
  }

  const handleOnNextClick = () => {
    count.set(count.get() + 1)
    if (count.get() === eventz.length) {
      count.set(0)
    }
    event.selectedEvent.set(count.get())
  };

  const handleOnPrevClick = () => {
    count.set(count.get() - 1)
    if (count.get() < 0) {
      count.set(eventz.length - 1)
    }

    event.selectedEvent.set(count.get());
  };

  if (event.editMode.get()) {
    return <></>
  }

  return (
    <>
      <div className="max-w-screen-xl">
        <div ref={slideRef} className="w-full flex justify-center relative select-none">
          <div className="w-9/12 md:w-11/12 lg:w-9/12 left-10">
            <div className="aspect-w-4 aspect-h-3">
              <img crossOrigin="anonymous" alt="qrcode" id="qrcode" src={eventz[selectedEvent]?.url}
                   onError={({ currentTarget }) => {
                     currentTarget.onerror = null; // preventz looping
                     currentTarget.src=`${process.env.PUBLIC_URL}/no-qrcode.png`;
                   }}/>
            </div>
          </div>
          <div
            className={`${eventz.length > 1 ? "" : "hidden"} absolute w-full top-1/2 transform -translate-y-1/2 flex justify-between items-start px-0`}>
            <button
              type="button"
              className="text-black p-1 rounded-full cursor-pointer transition bg-transparent"
              onClick={() => {
                handleOnPrevClick()
                slideRef.current?.classList.add("fade-anim");
              }}><IoChevronBack size={35}/></button>
            <button
              className="text-black p-1 rounded-full cursor-pointer transition bg-transparent"
              type="button"
              onClick={() => {
                handleOnNextClick()
                slideRef.current?.classList.add("fade-anim");
              }}><IoChevronForward size={35}/></button>
          </div>
        </div>
      </div>
      <div className="w-full text-center">
        <button
          className="text-black p-1 rounded cursor-pointer transition text-white text-xs bg-blue-400 w-24"
          onClick={copyQrCode}>Copy QR Code
        </button>
        <div className={`${qRCopied.get() ? "" : "hidden"} text-orange-400 text-xs`}>copied!</div>
      </div>
    </>
  )
}

export default QrcodeImageSlider
