import { eventStatus } from "../constants"

export const isOwner = (event, user) => {
  return event.uid === user.uid
}
export const checkStatus = (s) => {
  if (s !== eventStatus.STARTED && s !== eventStatus.LEFT_EVENT ) {
    return eventStatus.INLOBBY
  }
  return eventStatus.JOINED
}
