import { createState } from '@hookstate/core'

export const initial = {
	user: {
		email: '',
		isAuthenticated: false,
		emailVerified: false,
		displayName: '',
		anon: false,
		uid: '',
	},
	event: {
		loading: false,
		selectedEvent: 0,
		editMode: false,
	},
  onGoingEvents: {},
  eventDetail: {},
	events: [],
	participants: [],
	searchableEvents: [],
	modal: { open: false, id: null, data: {} },
	alert: { show: false, data: {} },
	callback: () => {},
	globalError: { auth: { code: '' } },
	isEventDeleted: false,
	isLoading: false,
	enableShare: true,
	sharedAll: false,
}
const store = createState(initial)

export default store
