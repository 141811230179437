import React from 'react'
import Layout from '../layouts/index'
import Page from '../components/page/page'

export default function IndexPage() {
	return (
		<Layout>
			<Page
				title="DynamoCash"
				content="A simple and seamless way to setup fund raising for your events"
			/>
	</Layout>
	)
}
