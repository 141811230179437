import React from 'react'
import PropTypes from "prop-types";
import { FaSpinner } from 'react-icons/fa';

const Card = ({title, description, loading, children}) => {
  return (
    <>
      <div className="relative flex flex-col min-w-0 md:max-w-96 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg h-[790px] scrollbar scrollbar-thumb-gray-400 scrollbar-track-gray-100">
        <div className="flex-auto m-6">
          <div className="flex flex-wrap">
            <div className="relative w-full pr-4 max-w-full flex-grow flex-1 text-center">
              <h5 className="text-orange-400 font-bold text-lg">
                {title}
                {loading && <FaSpinner icon="spinner" className="spinner animate-spin" />}
              </h5>
              <span className="text-xs italic text-gray-300">
                {description}
              </span>
            </div>
          </div>
          {children}
        </div>
      </div>
    </>
  )
}

Card.defaultProps = {
  title:'',
  description: '',
  children: null,
  loading: false
};

Card.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
};

export default Card

