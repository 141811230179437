import React from 'react'
import PropTypes from 'prop-types'

const Input = ({placeholder, onChange, errorMessage, multiline, showCount, onBlur, ...rest}) => {
  const [count, setCount] = React.useState(0);
  return multiline ?
    (<>
      <textarea
      {...rest}
      aria-placeholder={placeholder}
      className="resize-none w-full px-4 py-3 text-sm text-gray-500 bg-white border border-gray-300 rounded-sm shadow-none placeholder-italic"
      placeholder={placeholder}
      onBlur={onBlur}
      onChange={(e) => {
        e.preventDefault()
        const text = e.target.value
        if (text <= 140) {
          onChange(text)
          setCount(text?.length)
        }

      }}
      />
      {showCount && <p>{count}</p>}
      </>)
    : (
      <div className="flex-grow flex-shrink">
        <input
          {...rest}
          aria-placeholder={placeholder}
          className="w-full px-4 py-3 text-sm text-gray-500 bg-white border border-gray-300 rounded-sm shadow-none placeholder-italic"
          placeholder={placeholder}
          onChange={(e) => {
            e.preventDefault()
            console.log("you typed")
            onChange(e.target.value)
          }}
        />
        {errorMessage && <div className="text-red-500 text-sm">{errorMessage}</div>}
      </div>)
}

Input.defaultProps = {
  value: '',
  placeholder: '',
  onChange() {
  },
  type: 'text',
  errorMessage: '',
  name: '',
  multiline: false
}

Input.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.string,
  errorMessage: PropTypes.string,
  name: PropTypes.string,
  multiline: PropTypes.bool
}

export default Input;
