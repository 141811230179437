import React from 'react'
import ReactDOM from 'react-dom'
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/styles/tailwind.css";
import {Provider as AlertProvider} from 'react-alert'
import Routes from './routes'
import {BrowserRouter as Router} from "react-router-dom";
import AlertTemplate from "./components/alert-template/alert-template";
import {options} from "./utils/global-alerts-options";

ReactDOM.render(
  <React.StrictMode>
    {/*<CookieNotice />*/}
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_RECAPTCHA_ID}
      useRecaptchaNet
      useEnterprise
      scriptProps={{
        async: false, // optional, default to false,
        defer: false, // optional, default to false
        appendTo: 'head', // optional, default to "head", can be "head" or "body",
        nonce: undefined // optional, default undefined
      }}
    >
      <AlertProvider template={AlertTemplate} {...options}>
        <Router basename={process.env.PUBLIC_URL}>
          <Routes/>
        </Router>
      </AlertProvider>
    </GoogleReCaptchaProvider>
  </React.StrictMode>,
  document.getElementById('root'))
