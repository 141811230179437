import React from 'react'
import Input from '../forms/input/formik-input'
import {useState} from '@hookstate/core'
import {useFormik} from 'formik'
import {updateEvent} from '../../services/events'
import {getChangedValues, validationSchema} from '../../utils/form-utils'
import {useAlert} from 'react-alert'
import {updateSubscription} from '../../services/subscriptions'
import EditModeDatePicker from "../forms/date-picker/editmode-date-picker";
import Dropdown from "../forms/select/select";
import {getTimezoneOptions} from "../../utils/date-utils";
import TimeSelect from "../forms/select/time-select";

const dateOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
}

const hours = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12]

function make2digit(i) {
  const min = i.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })
  return min
}

const physical = {value: 'physical', label: 'In Person'}
const virtual = {value: 'virtual', label: 'Virtual'}
const hybrid = {value: 'hybrid', label: 'Hybrid'}
const eventOptionsMap = {physical, virtual, hybrid}
const eventOptions = [physical, virtual, hybrid]

const timezoneOptions = getTimezoneOptions()

function EventDetail({event, editMode, onCancel, onUpdate, children, user}) {
  const updatingEvent = useState(false)
  const alert = useAlert()
  const minutes = []
  const formik = useFormik({
    initialValues: {
      ...event,
      selectedOptions: {}
    },
    enableReinitialize: true,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (data, {resetForm}) => {
      updatingEvent.set(true)
      const obj = getChangedValues(data, event)
      if (event.subscriberUid === user.uid.get()) {
        if (obj.displayName) {
          updateSubscription(
            data,
            () => {
              onUpdate()
              updatingEvent.set(false)
              resetForm()
              alert.show('Event Successfully Updated', {type: 'success'})
            },
            (message) => {
              console.log(
                '🚀 ~ file: event-detail.js ~ line 70 ~ EventDetail ~ message',
                message
              )
            }
          )
        }
      } else {
        if (Object.keys(obj).length > 0) {
          const change = {
            id: data.id,
            eventName: data.eventName,
            uid: data.uid,
            ...obj,
          }
          updateEvent(
            change,
            (data) => {
              onUpdate()
              updatingEvent.set(false)
              resetForm()
              alert.show('Event Successfully Updated', {type: 'success'})
            },
            () => {
              alert.show('Unable to update event', {type: 'error'})
            }
          )
        }
      }
      // updatingEvent.set(false)
    },
  })

  for (let i = 0; i < 60; i++) {
    const min = make2digit(i)
    minutes.push(
      <option key={`minute${min}`} value={`${min}`}>
        {min}
      </option>
    )
  }
  const meetingUrl = formik.values.meetingUrl
  const m =
    meetingUrl?.includes('https') || meetingUrl?.includes('http')
      ? meetingUrl
      : `https://${meetingUrl}`
  let eventNameText = meetingUrl
  const eventMode = formik.values.eventMode
  const isPhysical = eventMode === 'physical'
  const isHybrid = eventMode === 'hybrid'
  const isVirtual = eventMode === 'virtual'
  if (isPhysical || isHybrid) {
    eventNameText = [
      formik.values.street,
      formik.values.city,
      formik.values.state,
      formik.values.zipcode,
      formik.values.country,
    ]
      .filter(Boolean)
      .join(', ')
  }
  let eventNameHtml
  let eventModeHtml = (
    <div className="grid grid-cols-2 gap-2 my-5 ">
      <div className="flex flex-row">
        <div
          className={`mt-1 w-3 h-3 ${
            eventMode === 'virtual' || eventMode === 'hybrid'
              ? 'bg-green-500'
              : 'bg-gray-300'
          }`}
        ></div>
        <div className="mx-2">Virtual</div>
      </div>
      <div className="flex flex-row">
        <div
          className={`mt-1 w-3 h-3 ${
            eventMode === 'physical' || eventMode === 'hybrid'
              ? 'bg-green-500'
              : 'bg-gray-300'
          }`}
        ></div>
        <div className="mx-2">In Person</div>
      </div>
    </div>
  )
  const shouldEdit = editMode && event.uid === user.uid.get();
  if (shouldEdit) {
    eventModeHtml = (
      <>
        <div className="font-bold text-orange-400 ">Event Mode</div>
        <div className="w-full">
          <Dropdown
            readOnly={false}
            selectedOption={eventOptionsMap[eventMode]}
            placeholder="Choose Event Mode*"
            options={eventOptions}
            onChange={(option) => {
              formik.setFieldValue('eventMode', option.value)
              const selectedOption = formik.values.selectedOption
              selectedOption['eventMode'] = option
              formik.setFieldValue('selectedOption', selectedOption)
            }}
          />
        </div>
      </>
    )
  }

  let eventNameId = 'eventName'
  let eventNameValue = formik.values.eventName
  let eventNameError = formik.errors.eventName
  if (event?.isHost === false) {
    eventNameId = 'displayName'
    eventNameValue = formik.values.displayName
    eventNameError = formik.errors.displayName
  }

  let eventAddressHtml
  if (isPhysical || isHybrid) {
    eventAddressHtml = (
      <Input
        readOnly={!editMode}
        value={eventNameText}
        handleChange={() => {
        }}
      />
    )
    if (shouldEdit) {
      eventAddressHtml = (
        <>
          <div className="w-full">
            <Input
              id="street"
              name="street"
              handleChange={formik.handleChange}
              value={formik.values.street}
              errorMessage={formik.errors.street}
              type="text"
              placeholder="Event Street"
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-1">
            <Input
              id="city"
              name="city"
              placeholder="Event City"
              type="text"
              handleChange={(e) => {
                e.preventDefault()
                const {value} = e.target
                formik.setFieldValue('city', value)
              }}
              value={formik.values.city}
              errorMessage={formik.errors.city}
            />
            <Input
              id="state"
              name="state"
              placeholder="Event State"
              type="text"
              handleChange={(e) => {
                e.preventDefault()
                const {value} = e.target
                formik.setFieldValue('state', value)
              }}
              value={formik.values.state}
              errorMessage={formik.errors.state}
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-1">
            <Input
              id="zipcode"
              name="zipcode"
              placeholder="Event Zip Code"
              type="text"
              handleChange={(e) => {
                e.preventDefault()
                const {value} = e.target
                formik.setFieldValue('zipcode', value)
              }}
              value={formik.values.zipcode}
              errorMessage={formik.errors.zipcode}
            />
            <Input
              id="country"
              name="country"
              placeholder="Event Country"
              type="text"
              handleChange={(e) => {
                e.preventDefault()
                const {value} = e.target
                formik.setFieldValue('country', value)
              }}
              value={formik.values.country}
              errorMessage={formik.errors.country}
            />
          </div>
        </>
      )
    }
  }
  //TODO: create util to not show comma if field is empty
  const hybridVenue = isHybrid && <div>{`${event.street}, ${event.city}, ${event.state}, ${event.zipcode}`}</div>

  const venueHtml = (
    <div className="grid grid-rows-1">
      <div className="font-bold text-orange-400">Venue:</div>
      <div className="text-2xl text-black">
        <>
          {shouldEdit ? eventAddressHtml : null}
        </>
        {(isVirtual || isHybrid) &&
        (shouldEdit ? (
          <Input
            id="meetingUrl"
            name="meetingUrl"
            placeholder="Meeting Url (Optional)"
            type="text"
            handleChange={(e) => {
              e.preventDefault()
              const {value} = e.target
              if (value) {
                formik.setFieldValue('meetingUrl', value)
              }
            }}
            value={meetingUrl}
            errorMessage={formik.errors.meetingUrl}
          />
        ) : (
          <div>
            {hybridVenue}
            <div className="text-blue-300 underline">
              {meetingUrl ? <a href={m}>Online (click to join)</a> : 'Online'}
            </div>
          </div>
        ))}
      </div>
    </div>
  )

  let displayNameId = 'displayName'
  let displayNameValue = formik.values.displayName
  let displayNameError = formik.errors.displayName
  if (event?.isHost === false) {
    displayNameId = 'displayName'
    displayNameValue = formik.values.displayName
    displayNameError = formik.errors.displayName
  }
  //let editDisplayName
  const editDisplayName = (
    <Input
      id={displayNameId}
      name={displayNameId}
      readOnly={false}
      handleChange={formik.handleChange}
      value={displayNameValue}
      errorMessage={displayNameError}
      type="text"
      placeholder="Event Display Name"
    />
  )

  const dateHtml = (
    <>
      <div className="font-bold text-orange-400">Date</div>
      <div className="text-2xl text-black">
        <EditModeDatePicker
          readOnly={!editMode || event.uid !== user.uid.get()}
          errorMessage={formik.errors.date}
          required
          value={event?.date}
          onSelect={(dateObject) => {
            formik.setFieldValue('date', dateObject.date)
            formik.setFieldValue('jsonDate', dateObject.toJSON())
          }}
        />
      </div>
    </>
  )

  const startTimeHtml = (
    <TimeSelect
      readOnly={!editMode || event.uid !== user.uid.get()}
      timeLabel="Start Time:"
      timezoneLabel="Start timezone:"
      errorMessage={{time: formik.errors.startTime}}
      onTimeChange={(option) => {
        formik.setFieldValue('startTime', option.value)
        const selectedOption = formik.values.selectedOption
        selectedOption['startTime'] = option
        formik.setFieldValue('selectedOption', selectedOption)
      }}
      onTimezoneChange={(option) => {
        formik.setFieldValue('startTimeZone', option.value)
        const selectedOption = formik.values.selectedOption
        selectedOption['startTimeZone'] = option
        formik.setFieldValue('selectedOption', selectedOption)
      }}
      timePlaceholder="Start Time*"
      timezonePlaceholder="Start Timezone*"
      selectedTimeOption={{value: event?.startTime, label: event?.startTime}}
      selectedTimezoneOption={{value: event?.startTimeZone, label: event?.startTimeZone}}
    />
  )

  const endTimeHtml = (
    <TimeSelect
      readOnly={!editMode || event.uid !== user.uid.get()}
      timeLabel="End Time:"
      timezoneLabel="End timezone:"
      errorMessage={{time: formik.errors.endTime}}
      onTimeChange={(option) => {
        formik.setFieldValue('endTime', option.value)
        const selectedOption = formik.values.selectedOption
        selectedOption['endTime'] = option
        formik.setFieldValue('selectedOption', selectedOption)
      }}
      onTimezoneChange={(option) => {
        formik.setFieldValue('endTimeZone', option.value)
        const selectedOption = formik.values.selectedOption
        selectedOption['endTimeZone'] = option
        formik.setFieldValue('selectedOption', selectedOption)
      }}
      timePlaceholder="End Time*"
      timezonePlaceholder="End Timezone*"
      selectedTimeOption={{value: event?.endTime, label: event?.endTime}}
      selectedTimezoneOption={{value: event?.endTimeZone, label: event?.endTimeZone}}
    />
  )

  const eventDetailsHtml = (
    <div className="grid grid-rows-1">
      {formik.values.eventDetails && <div className="font-bold text-orange-400">Event Details:</div>}
      <div className="w-full text-lg">
        <Input
          readOnly={!editMode || event.uid !== user.uid.get()}
          id="eventDetails"
          name="eventDetails"
          placeholder="Event Details (Optional)"
          handleChange={formik.handleChange}
          value={formik.values.eventDetails}
          errorMessage={formik.errors.eventDetails}
          multiline
          rows={5}
        />
      </div>
    </div>
  )

  const contactDetailsHtml = (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-2 md:gap-1 text-black text-lg">
      <div className="font-bold text-orange-400 ">Phone:</div>
      <div className="font-bold text-orange-400 ">Email:</div>
      <div className="w-full">
        <Input
          readOnly={!editMode || event.uid !== user.uid.get()}
          id="phone"
          name="phone"
          placeholder="Phone*"
          type="text"
          pattern="[0-9]*"
          handleChange={(e) => {
            e.preventDefault()
            const { value } = e.target
            const regex =
              /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/
            if (regex.test(value.toString())) {
              formik.setFieldValue('phone', value)
            }
          }}
          value={formik.values.phone}
          errorMessage={formik.errors.phone}
        />
      </div>
      <div className="w-full">
          <Input
            readOnly={!editMode || event.uid !== user.uid.get()}
            id="email"
            name="email"
            handleChange={formik.handleChange}
            value={formik.values.email}
            errorMessage={formik.errors.email}
            placeholder="Email Address*"
          />
        </div>
    </div>
  )

  let ownerEditableFormFields
  if (event?.isHost !== false) {
    ownerEditableFormFields = (
      <>
        {eventModeHtml}
        {children}
        {venueHtml}
        {dateHtml}
        {startTimeHtml}
        {endTimeHtml}
        {contactDetailsHtml}
        {eventDetailsHtml}
      </>
    )
  }
  if (editMode) {
    eventNameHtml = (
      <Input
        id={eventNameId}
        name={eventNameId}
        readOnly={false}
        handleChange={formik.handleChange}
        value={eventNameValue}
        errorMessage={eventNameError}
        type="text"
        placeholder="Event Name*"
      />
    )
  }

  let nameToEditHtml = eventNameHtml
  if (editMode && event.uid !== user.uid.get()) {
    nameToEditHtml = editDisplayName
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="w-full text-sm text-blueGray-400 grid grid-cols-1 gap-2 w-full mx-1">
        {/* {nameToEdit} */}
        {nameToEditHtml}
        {ownerEditableFormFields}
        <div className={`${!editMode ? 'hidden' : ''} text-center w-full`}>
          <button
            type="button"
            onClick={() => {
              // updatedEvent.set({})
              formik.resetForm()
              onCancel()
            }}
          >
            Cancel
          </button>
          <button
            type="submit"
            className={`${
              updatingEvent.get() ? 'bg-orange-200' : 'bg-orange-500'
            } w-5/12 h-12 items-center text-white font-bold py-2 px-4 focus:outline-none focus:shadow-outline`}
          >
            {updatingEvent.get() ? 'Updating Event...' : 'Update Event'}
          </button>
        </div>
      </div>
    </form>
  )
}

EventDetail.defaultProps = {
  editMode: false,
  user: null,
}

EventDetail.propTypes = {}

export default EventDetail
