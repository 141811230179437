/*eslint-disable*/
import React, { useEffect } from 'react'
import { useState } from '@hookstate/core'
import store from '../../store/store'
import { eventStatus, modalID } from '../../constants'
import Input from '../forms/input/formik-input'
import { useParams } from 'react-router-dom'

export default function Guest({participant, enableShare, isHost}) {
    const {modal,eventDetail} = useState(store)
    const eventObject = eventDetail.get()
    const {email = "", guestDisplayName = "", donationAmount = 0, status = "started"} = participant
    const participantStatus = status === eventStatus.JOINED? "bg-green-500":
    status === eventStatus.LEFT_EVENT? "bg-yellow-300": "bg-gray-300";

    const handleShare = ()=>{
        modal.set({open: true, data:{
            id: modalID.SHARE, title: "Are you sure you want to share contribution details?",
         message:`Sharing these details will allow the selected guest to see the funds as they come in.`,
         participant
        }})
    }

    const handleUnShare = ()=>{
        modal.set({open: true, data:{
            id: modalID.SHARE, title: "Unshare Screen with user",
         message:`Are you sure you want to unshare event screen to this user`,
         participant
        }})
    }

	return (

            <div className="flex items-center h-12 px-5 ">
                <div className='mr-5 flex justify-even items-center'>
                    <div className={`${participantStatus} rounded-full align-middle h-4 w-4 ml-10 `}></div>
                    {
                        enableShare && isHost?  
                    <div className="text-base ml-5">
                        <Input
                            checked={participant.share? true:false}
                            handleChange={!participant.share?handleShare:handleUnShare} 
                            name="isAgreement" 
                            type="checkbox" />
                    </div>: null
                    }
                   
                </div>

                <div className='basis-1/2'>
                    <p className="text-left align-left text-gray-500 text-sm">{guestDisplayName || email}</p>
                </div>

                {
                    isHost || eventObject?.share?
                    <div className=' relative basis-2/6'>
                        <div className='absolute top-4 left-4 h-6 w-6 overflow-visible bg-secondary-500  rounded-full  '></div>
                        <div className='absolute  right-5 h-3 w-3 overflow-visible bg-secondary-500 rounded-full '></div>
                        <div className="m-auto flex overflow-visible bg-secondary-500 rounded-full align-left h-14 w-14 p-5 justify-center items-center ">
                            <p className='text-center text-white text-1xl'>${donationAmount}</p>
                        </div>

                </div> : null
                }

                

            </div>

	)
}
