import React from 'react'
import PropTypes from 'prop-types'
import Button from "../forms/button/button";

const ModalDeprecated = ({
                 title,
                 children,
                 opened,
                 onClose,
                 onSubmit,
                 allowSubmit,
                 cancelText,
                 submitText,
                 showActionButtons
               }) => {
  return (
    <>
      {opened ? (
        <>
          <div
            className="absolute justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-full md:w-3/4 my-6 mx-auto ">
              {/*content*/}
              <div
                className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                {title && <div
                  className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-3xl font-semibold">
                    {title}
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => {
                    }}
                  >
                    <span
                      className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>}
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <div className="my-4 text-blueGray-500 text-lg leading-relaxed">
                    {children}
                  </div>
                </div>
                {/*footer*/}
                {showActionButtons ?
                  <div
                    className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                    <button
                      className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => {
                        onClose()
                      }}
                    >
                      {cancelText}
                    </button>
                    <Button
                      onClick={() => {
                        onSubmit()
                      }}
                      label={submitText}
                    />
                  </div>
                  : null}
                {/*<div className="absolute top-3 right-3" onClick={() => {onClose()}}>*/}
                {/*  <GrClose color="#A1A6B3" size="2em"/>*/}
                {/*</div>*/}
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>)
}

ModalDeprecated.defaultProps = {
  title: "",
  children: null,
  opened: false,
  onClose() {
  },
  onSubmit() {
  },
  allowSubmit: true,
  cancelText: "Close",
  submitText: "Save Changes",
  showActionButtons: true
}

ModalDeprecated.propTypes = {
  title: PropTypes.string,
  children: PropTypes.array,
  opened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  allowSubmit: PropTypes.bool,
  showActionButtons: PropTypes.bool
}

export default ModalDeprecated
