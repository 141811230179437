import React from 'react'
import { Formik, Form, useField, ErrorMessage } from 'formik'
import { useState } from '@hookstate/core'
import { object, string, ref, bool } from 'yup'
import { AuthContext } from '../components/auth/auth-provider'
import store from '../store/store'
import { useNavigate, Link } from 'react-router-dom'
import ModalDeprecated from '../components/modal/modal-deprecated'
import { BsFillCheckCircleFill } from 'react-icons/bs'

const initialValues = {
	firstName: '',
	lastName: '',
	phoneNumber: '',
	email: '',
	password: '',
	passwordConfirmation: '',
	referralCode: '',
	isAgreement: false,
}
const SignUp = () => {
	let navigate = useNavigate()
	const { globalError } = useState(store)
	const { signUp } = React.useContext(AuthContext)
	const loading = useState(false)
	const error = useState('')
	const hasError = useState(false)
	const formValues = useState(initialValues)
	const registrationSuccessful = useState(false)
	let { code } = globalError.auth.get()

	const onSubmit = async () => {
		if (!hasError.get()) {
			loading.set(true)
			error.set('')
			signUp(
				formValues.get(),
				() => {
					loading.set(false)
					registrationSuccessful.set(true)
				},
				(e) => {
					loading.set(false)
					if (code === 'auth/email-already-in-use') {
						error.set('email already registered.')
					} else if (code === '') {
						error.set('')
					} else {
						error.set('error signing up!')
					}
				}
			)
		}
	}
	// useEffect(() => {}, [code])
	return (
		<ModalDeprecated
			opened={true}
			allowSubmit={!hasError}
			onSubmit={onSubmit}
			onClose={() => {
				navigate(-1)
			}}
			cancelText="Cancel"
			submitText="Sign Up"
			showActionButtons={!registrationSuccessful.get()}
		>
			<div className="w-full h-50 mt-0 flex items-center justify-center flex-col ">
				{registrationSuccessful.get() ? (
					<>
						<div className="p-5">
							<BsFillCheckCircleFill color="orange" size="3em" />
						</div>
						<div>Registration Successful!</div>
						<div>
							A verification email has been sent to {formValues.email.get()}.
						</div>
						<div>Please verify your email and then login to continue.</div>
					</>
				) : (
					<Formik
						initialValues={initialValues}
						onSubmit={onSubmit}
						validationSchema={signUpValidation}
					>
						{({ errors, values }) => {
							console.log(
								'🚀 ~ file: sign-up.js ~ line 91 ~ SignUp ~ errors',
								code
							)
							formValues.set(values)
							hasError.set(Object.keys(errors).length > 0)
							return (
								<Form className="w-full md:w-3/4 shadow-md rounded px-8 pt-6 pb-8 bg-gray-100">
									<div className="font-medium text-gray-600 pb-4">SIGN UP</div>
									<Input name="firstName" placeholder="FirstName" />
									<Input name="lastName" placeholder="LastName" />
									<Input name="phoneNumber" placeholder="Phone" />
									<Input name="email" placeholder="Email" />
									{code === 'auth/email-already-in-use' && (
										<div className={'mb-4 text-red-500 text-xs'}>
											This Email Already In Use
										</div>
									)}
									<Input
										name="password"
										placeholder="Password"
										type="password"
									/>
									<Input
										name="passwordConfirmation"
										placeholder="Re-enter Password"
										type="password"
									/>
									<Input
										name="referralCode"
										placeholder="Referral Code (Optional)"
										type="text"
									/>
									<div className="flex space-x-5 ...">
										<div className="text-base">
											<Input name="isAgreement" type="checkbox" />
										</div>
										<div className="text-base px-6">
											I agree with the{' '}
											<Link
												to="/TermsAndConditions"
												target="_blank"
												className="underline text-blue-600/100 hover:text-orange-800 "
											>
												terms and conditions
											</Link>
										</div>
									</div>
								</Form>
							)
						}}
					</Formik>
				)}
			</div>
		</ModalDeprecated>
	)
}

const signUpValidation = object().shape({
	firstName: string().required('firstname required'),
	lastName: string().required('lastname required'),
	phoneNumber: string().required('phone number required'),
	email: string().required('Required').email('Valid email required'),
	password: string()
		.min(8, 'please enter a minimum of 8 characters')
		.required('password is required'),
	passwordConfirmation: string().oneOf(
		[ref('password'), null, ''],
		'Passwords must match'
	),
	isAgreement: bool().oneOf([true], 'Accept Terms & Conditions is required'),
})

const Input = ({ name, label, ...props }) => {
	const [field, meta] = useField(name)

	let inputStyle = `${
		meta.error && meta.touched ? 'border-red-500' : ''
	} shadow appearance-none rounded w-full px-4 py-3 text-sm bg-white rounded-sm shadow-none border-0 leading-tight focus:outline-none focus:shadow-outline`

	let outterStyle = 'mb-4'
	let errorMessageStyles = 'text-red-500 text-xs'

	if (props.type === 'checkbox') {
		inputStyle = `${meta.error && meta.touched ? 'border-red-500' : ''}`
		outterStyle = ''
		errorMessageStyles = 'text-red-500 text-xs absolute ...'
	}

	return (
		<div className={outterStyle}>
			{/*<label className="block text-gray-700 text-sm font-bold" for={field.name}>*/}
			{/*  {label}*/}
			{/*</label>*/}
			<input className={inputStyle} {...field} {...props} />

			<ErrorMessage
				name={field.name}
				component="div"
				className={errorMessageStyles}
			/>
		</div>
	)
}
//absolute ...
export default SignUp
