/*eslint-disable*/
import React from 'react'
import { useState } from '@hookstate/core'
import store from '../../store/store'
import Guest from '../guest'
import PropTypes from "prop-types";
import { useParams } from 'react-router-dom';
import Input from '../forms/input/formik-input';
import { updateSubscriptionInRealTimeDatabase } from '../../services/subscriptions';
import { updateEvent } from '../../services/events';

export default function GuestList({participants = []}) {

  const {user, onGoingEvents, isLoading,eventDetail, enableShare, sharedAll} = useState(store)
  const eventObject = eventDetail.get()
  const {eventId} = useParams()
  const event = onGoingEvents[eventId].get()
  const share = enableShare.get()
  const shared = sharedAll.get()

  const shareAll = (share) =>{

    participants.forEach(participant=>{
    
       updateSubscriptionInRealTimeDatabase(eventId,participant?.email, {
        share
      })
    })
    updateEvent({
      id: event.id,
      eventName: event.eventName,
      sharedAll: share,
      uid: event.uid
    }, ()=>{
      sharedAll.set(!shared)
      isLoading.set(false)
      enableShare.set(false)
    }, ()=>{
      isLoading.set(false)
    });
   

  }

	return (
        <div className="flex flex-col flex-1">
            <div className="divide-y divide-slate-200">
            <div className="flex justify-between items-center h-14 px-5 my-2 border-b-1 border-gray-200 mt-10">
                <div className="text-left text-gray-500 text-1xl">Participating Guests    <span className='ml-10 text-green-500'>{participants? participants.length:"0"}</span> </div>
                <div className="text-left text-gray-500 text-1xl">
                   {
                        share && event?.isHost?  
                    <div className="text-base my-10">
                      <p className='text-sm'>share all</p>
                        <Input
                         name="isAgreement"
                          type="checkbox"
                          checked={shared}
                          handleChange={()=>shareAll(!shared)} 
                           />
                    </div>: null
                    } 
                    </div>
               {
                event?.isHost && 
                <div className="text-left text-gray-500 text-sm">
                  <button className='bg-green-500 font-normal text-white py-2 px-5 rounded-none'
                   onClick={()=>enableShare.set(!share)} >Share</button>
                  </div>
               }
                
            </div>
            {
                participants && participants.length?
                participants.map(participant=>{

                  return  <Guest participant={participant} enableShare={share} isHost={event?.isHost} />

                }): null
            }
            </div>
        </div>
	)
}

GuestList.defaultProps = {
  participants: []
}

GuestList.prototype = {
  participants: PropTypes.array
}
