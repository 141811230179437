import React from 'react'
import PropTypes from 'prop-types'

const Button = ({ onClick, label, bgColor, customStyle}) => {
	return (
		<div className="control">
			<button
				className={`-mt-px text-white shadow-lg ${bgColor}  disabled:opacity-50 ${customStyle}`}
				onClick={onClick}
				onKeyDown={onClick}
			>
				{label}
			</button>
		</div>
	)
}

Button.defaultProps = {
	onClick() {},
	label: '',
	bgColor: 'bg-secondary-400',
}

Button.propTypes = {
	onClick: PropTypes.func,
	label: PropTypes.string,
	bgColor: PropTypes.string,
}

export default Button
