import { getFunctions, httpsCallable } from 'firebase/functions'
import store from '../store/store'

export const retrieveUserInfo = (onSuccess) => {
	store.user.loading.set(true)
	const retrieveInfo = httpsCallable(getFunctions(), 'users-retrieveUserInfo')
	retrieveInfo()
		.then((result) => {
			if (result.data) {
				store.user.merge(result.data)

				if (onSuccess) {
					onSuccess()
				}
			}

			store.user.loading.set(false)
		})
		.catch((error) => {
			// Getting the Error details.
			const code = error.code
			const message = error.message
			const details = error.details
			console.error(code, message, details)
		})
}

export const createUserInfo = (data, onSuccess, onFailure) => {
	store.user.loading.set(true)
	const update = httpsCallable(getFunctions(), 'users-createUserInfo')
	update(data)
		.then((result) => {
			store.user.merge(result.data)
			if (onSuccess) {
				onSuccess(data)
			}
		})
		.catch((error) => {
			// Getting the Error details.
			const code = error.code
			const message = error.message
			const details = error.details
			console.log(code, message, details)
			if (onFailure) {
				onFailure()
			}
		})
}
