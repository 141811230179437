import React, { useEffect } from 'react'
import { useState } from '@hookstate/core'
import store from '../../store/store'
import CardHostEvent from '../../components/cards/card-host-event'
import CardManageEvents from '../../components/cards/card-manage-events'
import EventDetails from '../../components/event-details-card/event-details'
import {
	retrieveEvents,
} from '../../services/events'
import DeleteModal from '../../components/modal/delete-modal'
import { deleteEvent } from '../../services/events'
import { useAlert } from 'react-alert'

export default function Home() {
	const { user, event, events, isEventDeleted } = useState(store)
	const emailVerified = user.emailVerified.get()
	const { selectedEvent } = event.get()
	const eventObject = events[selectedEvent]?.get()
	const alert = useAlert()
	useEffect(() => {
		event.loading.set(true)
		if (isEventDeleted.get() === false) {
			retrieveEvents({filter: {nowAndFuture: true}})
		}
	}, [])
	const handleDelete = () => {
		const event = events[selectedEvent].get()
		deleteEvent(
			{
				id: event.id,
				status: 'inactive',
				eventName: event.eventName,
				uid: event.uid,
				isDeleted: true,
				deletedAt: new Date(),
				deletedBy: user.uid.get(),
			},
			(event) => {
				alert.show('Event Successfully Deleted', { type: 'success' })
			}
		)
	}

	return (
		<>
			<DeleteModal
				opened={isEventDeleted.get()}
				text={`Are you sure you want to delete ${
					eventObject?.displayName || eventObject?.eventName
				}  event`}
				title="Delete"
				actionStatus={false}
				handleDelete={handleDelete}
			/>
			{!emailVerified && (
				<div className="w-full text-center">Please Verify Your email</div>
			)}
			<div className="flex flex-wrap mt-4 ">
				<div className="px-2 w-full h-screen">
					<div className="flex w-full md:max-w-[1216px] -mx-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
						<div className="z-10 ">
							<CardHostEvent />
						</div>
						<div className=" ">
							<CardManageEvents />
						</div>
						<div className="">
							<EventDetails />
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
