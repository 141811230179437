import colors from "tailwindcss/colors";

export const customStyles = (width = 194, height = 45) => {
  const textColor = colors.gray['500'];
  return {
    control: styles => {
      return ({...styles, backgroundColor: 'white', color: textColor})
    },
    singleValue: (provided) => ({
      ...provided,
      color: textColor
    }),
    option: (provided, state) => ({
      ...provided,
      color: textColor,
      backgroundColor: state.isSelected ? colors.orange[400] : 'white',
      "&:hover": {
        backgroundColor: colors.orange['100'],
      },
    }),
    valueContainer: (base) => ({
      ...base,
      minHeight: height,
      color: '#71717a'
    })
  }
}
