import React from 'react'
import Modal from './modal'
import { GrClose } from 'react-icons/gr'
import { useState } from '@hookstate/core'
import store from '../../store/store'
import {updateSubscription, updateSubscriptionInRealTimeDatabase} from "../../services/subscriptions";
import {displayNameSchema} from "../../utils/form-utils";
import { useFormik } from 'formik'
import Input from '../forms/input/formik-input'
const DisplayNameModal = ({ opened, text, title, eventId, displayName  }) => {
	const { events,onGoingEvents, user, modal, eventDetail, alert, isLoading } = useState(store)
  const [submitting, setSubmitting] = React.useState(false)
  const eventObject = eventDetail.get()
  const event = onGoingEvents[eventId].get()
  const actionButtons = null

  const formik = useFormik({
		initialValues: {
			displayName: displayName,
		},
		displayNameSchema,
		validateOnChange: false,
		validateOnBlur: false,

		onSubmit: (data, { resetForm }) => {
			
      const {selectedOption, ...others} = data

			setSubmitting(true)
			updateSubscriptionInRealTimeDatabase(event?.id,eventObject?.email, {
				guestDisplayName: others?.displayName,
			  }).then((res) => {
				// Data saved successfully!
				isLoading.set(false)
					resetForm()
					setSubmitting(false)
					modal.open.set(false)
					alert.set({show: true, data: {message: "Display Name Successfully Change", type: 'success'}})
				})
				.catch((error) => {
					setSubmitting(false)
					isLoading.set(false)
					
				// The write failed...
				alert.set({show: true, data: {message: "Unable to change display", type: 'success'}})
				});

		},
	})

  const dynamicInput = (
    <div className="m-4">
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
      <Input
        placeholder="Enter display name"
        id="displayName"
		name="displayName"
		handleChange={formik.handleChange}
		value={formik.values.displayName}
		errorMessage={formik.errors.displayName}
		type="text"
      />
       <div className="text-center my-10">
       <button
			className={`${
				submitting? 'bg-orange-200' : 'bg-orange-500'
			} w-10/12 h-12 items-center text-white font-bold py-2 px-4 focus:outline-none focus:shadow-outline`}
			type="submit"
		>
			{submitting ? 'Saving Name...' : 'Save Name'}
		</button>
      </div>
      </form>
    </div>
  )

	const header = (
    <div className="flex flex-row justify-center items-center text-3xl bg-orange-400 text-white w-full">
      <div className="bg-orange-400 p-6 font-bold text-white text-3xl">
        {title}
      </div>
    </div>
	)
	return (
		<Modal
			header={header}
			actionButtons={actionButtons}
			opened={opened}
			icon={<GrClose onClick={() => modal.open.set(false)}/>}
		>
			<div className="grid grid-columns-1 md:grid-rows-1 gap-3 text-center">
				<div className="text-gray-400">{text}</div>
        {dynamicInput}
			</div>
		</Modal>
	)
}

DisplayNameModal.propTypes = {}

DisplayNameModal.defaultProps = {
	title: '',
	opened: false,
	onClose() {},
	text: '',
	actionStatus: false,
}

export default DisplayNameModal
