import { useState } from '@hookstate/core'
import { BsXOctagonFill } from 'react-icons/bs'
import ModalDeprecated from '../../components/modal/modal-deprecated'
import store from '../../store/store'
import {useEffect} from 'react'
import { Navigate, useLocation } from 'react-router-dom'

const VerifyEmail = () => {
  let location = useLocation()
	const { user } = useState(store)

	const email = user.email.get()
	useEffect(() => {
		if (user.emailVerified === true) {
			return <Navigate to="/login" state={{ from: location }} />
		}
	}, [user])
	return (
		<>
			<ModalDeprecated
				opened={true}
				allowSubmit={!false}
				onSubmit={() => {}}
				onClose={() => {
					//	navigate(-1)
				}}
				cancelText="Cancel"
				submitText="Sign Up"
				showActionButtons={false}
			>
				<div className="w-full h-50 mt-0 flex items-center justify-center flex-col ">
					<div className="p-5">
						<BsXOctagonFill color="orange" size="3em" />
					</div>
					<div>Please click on the Email Verification link </div>
					<div>That email has been sent to {email}</div>
				</div>
			</ModalDeprecated>
		</>
	)
}

export default VerifyEmail
