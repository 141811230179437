import React from 'react'
import PropTypes from 'prop-types'

const Checkbox = ({name, onChange, checked, description, errorMessage}) => {
  return (
    <div className=" w-full flex-row flex-grow flex-shrink mr-2 py-1.5">
      <input
        className="bg-white border border-gray-300 rounded-sm shadow-none"
        type="checkbox"
        name={name}
        onChange={(e) => {
          onChange(e.target.checked)
        }}
        checked={checked}
      />
      {description && <label className="text-sm" htmlFor={name}>  {description}</label>}
      {errorMessage && <div>{errorMessage}</div>}
    </div>
  )
}

Checkbox.defaultProps = {
  value: '',
  placeholder: '',
  onChange() {
  },
  type: '',
  errorMessage: '',
  name: ''
}

Checkbox.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.string,
  errorMessage: PropTypes.string,
  name: PropTypes.string,
}

export default Checkbox;
