import {getFunctions, httpsCallable} from "firebase/functions";

export const addFutureUser = async (data) => {
  const addUser = httpsCallable(getFunctions(), 'futureUsers-addFutureUser');
  await addUser(data).then((result) => {
    // Read result of the Cloud Function.
    /** @type {any} */
    const data = result.data;
    const sanitizedMessage = data.text;
    console.log(sanitizedMessage)
  })
    .catch((error) => {
      // Getting the Error details.
      const code = error.code;
      const message = error.message;
      const details = error.details;
      console.log(code, message, details)
    });

}
