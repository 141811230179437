import React from 'react'
import InfoIcon from './InfoIcon'
import ErrorIcon from './ErrorIcon'
import {BsCheckCircle} from 'react-icons/bs'
import AlertModal from '../modal/alert-modal';
import { GrClose } from 'react-icons/gr';

const AlertTemplate = ({ message, show = false, type = "success", style, close,  }) => {
 
  let bgColor = 'bg-orange-400'
  
  if (type === 'error') {
    bgColor = 'bg-red-400'
  }
  return (
    <AlertModal
    
    opened={show}
    icon={<GrClose/>}>
    <div className={`absolute top-2  right-0 flex items-center ${bgColor} text-white text-lg font-bold px-4 py-3 min-w-fit rounded-sm ${style}`}>
      {type === 'info' && <InfoIcon className="mx-2 text-white" />}
      {type === 'success' && <BsCheckCircle className="mx-2" size={37} color="white"/>}
      {type === 'error' && <ErrorIcon />}
      <p className=''>{message}</p>
    </div>
  </AlertModal>
   
  )
}

export default AlertTemplate
