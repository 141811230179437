export const sanitizedEmail = (email) => {
  let sanitizedEmail
  if (email) {

    sanitizedEmail = email.replace(/\./g, '*dot*')
      .replace(/\#/g, '*hash*')
      .replace(/\$/g, "*dollar*")
      .replace(/\//g, "*forwardslash*")
      .replace(/\[/g, "*opensquare*")
      .replace(/\]/g, "*closesquare*")

  }

  return sanitizedEmail
}

export const deSanitizedEmail = (email) => {
  if (!email) {
    return email
  }
  let sanitizedEmail
  if (email) {
    sanitizedEmail = email
      .replace(/\*dot\*/g, '.')
      .replace(/\*hash\*/g, '#')
      .replace(/\*dollar\*/g, "$")
      .replace(/\*forwardslash\*/g, "/")
      .replace(/\*opensquare\*/g, "[")
      .replace(/\*closesquare\*/g, "]")

  }

  return sanitizedEmail
}
