import React, {useState} from 'react'
import classnames from 'classnames'
import Input from "./input/input";
import Button from "./button/button";
import {useState as useGlobalState} from "@hookstate/core";
import store from "../../store/store";
import {addFutureUser} from "../../services/future-users";
import {EMAIL_PATTERN} from "../../constants";

const NewsLetterForm = ({className, submit = 'Submit'}) => {
  const {modal} = useGlobalState(store)
  const [email, setEmail] = useState('')
  const [errorMessage, setErrorMessage] = useState({})

  const onSubmit = () => {

    if (!email || !EMAIL_PATTERN.test(email)) {
      setErrorMessage({...errorMessage, email: "Please enter valid email address."})
    } else {
      setErrorMessage({})

      modal.set({
        open: true,
        callback: async () => {
          let data = {email, ...modal.data.get()};
          await addFutureUser(data)
        }
      })

    }
  }

  return (
    <>
      <div className={classnames(
        'newsletter-form is-revealing md:flex',
        className
      )}
      >

        <div className="flex-grow flex-shrink mr-2">
          <Input
            type="email"
            name="email"
            placeholder="Your best email&hellip;"
            onChange={setEmail}
            value={email}
            errorMessage={errorMessage.email}
          />
        </div>

        <div className="control">
          <Button
            onClick={onSubmit}
            label={submit}
          />
        </div>
      </div>
    </>
  )
}

export default NewsLetterForm
