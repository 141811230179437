import React from 'react'
import { useFormik } from 'formik'
import Input from '../forms/input/formik-input'
import { useState } from '@hookstate/core'
import { createEvent } from '../../services/events'
import Card from './card'
import { useAlert } from 'react-alert'
import { customStyles } from '../../utils/select-util'
import { validationSchema } from '../../utils/form-utils'
import {eventOptions, timeOptions} from "../../constants";
import Dropdown from "../forms/select/select";
import EditModeDatePicker from "../forms/date-picker/editmode-date-picker";
import PlacesTypeAhead from "../forms/select/places-select";

export default function CardHostEvent() {
	const submitting = useState(false)
	const eventMode = useState('')
  const date = useState({})
	const alert = useAlert()

	const formik = useFormik({
		initialValues: {
			email: '',
			eventDetails: '',
			phone: '',
			eventName: '',
			date: '',
			eventType: '',
			eventMode: '',
			meetingUrl: '',
			street: '',
			city: '',
			state: '',
			zipcode: '',
			country: '',
			eventAccess: '',
			eventLocation: '',
			locationDetails: {},
      selectedOption: {},  //hide, should not submit field to server
		},
		validationSchema,
		validateOnChange: false,
		validateOnBlur: false,
		onSubmit: (data, { resetForm }) => {
      const {selectedOption, ...others} = data

			submitting.set(true)
			createEvent(
				others,
				() => {
					submitting.set(false)
					resetForm()
					alert.show('Event Successfully Created', { type: 'success' })
				},
				() => {
					alert.show('Unable to create event', { type: 'error' })
				}
			)
		},
	})

	return (
		<>
			<Card title="Host an Event" description="Create an Event to Host">
				<form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
					<div className="w-full text-sm text-blueGray-400 mt-4 grid grid-rows-1 gap-2 md:gap-1">
						<div className="w-full">
							<Input
								id="eventName"
								name="eventName"
								handleChange={formik.handleChange}
								value={formik.values.eventName}
								errorMessage={formik.errors.eventName}
								type="text"
								placeholder="Event Name*"
							/>
						</div>
						<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 gap-2 md:gap-1">
							<div className="col-span-1 z-[70]">
									<Dropdown
                    selectedOption={formik.values.selectedOption?.eventMode}
                    errorMessage={formik.errors.eventMode}
										placeholder="Choose Event Mode*"
										options={[
											{ value: 'physical', label: 'In Person' },
											{ value: 'virtual', label: 'Virtual' },
											{ value: 'hybrid', label: 'Hybrid' },
										]}
										onChange={(option) => {
											formik.setFieldValue('eventMode', option.value)
                      const selectedOption = formik.values.selectedOption
                      selectedOption['eventMode'] = option
                      formik.setFieldValue('selectedOption', selectedOption)
											eventMode.set(option.value)
										}}
									/>
							</div>
							<div className="col-span-1 z-[60]">
								<div className="w-full">
									<Dropdown
                    selectedOption={formik.values.selectedOption?.eventAccess}
                    errorMessage={formik.errors.eventAccess}
										placeholder="Choose Accessibility*"
										options={[
											{ value: 'public', label: 'Public' },
											{ value: 'protected', label: 'Protected - Not Searchable' },
											{
												value: 'strictlyByInvite',
												label: 'Private - Strictly By Invitation',
											},
										]}
										onChange={(option) => {
											formik.setFieldValue('eventAccess', option.value)
                      const selectedOption = formik.values.selectedOption
                      selectedOption['eventAccess'] = option
                      formik.setFieldValue('selectedOption', selectedOption)
										}}
									/>
								</div>
							</div>
						</div>
						{(eventMode.value === 'virtual' ||
							eventMode.value === 'hybrid') && (
							<Input
								id="meetingUrl"
								name="meetingUrl"
								placeholder="Meeting Url (Optional)"
								type="text"
								handleChange={(e) => {
									e.preventDefault()
									const { value } = e.target
									if (value) {
										formik.setFieldValue('meetingUrl', value)
									}
								}}
								value={formik.values.meetingUrl}
								errorMessage={formik.errors.meetingUrl}
							/>
						)}
						{(eventMode.value === 'physical' ||
							eventMode.value === 'hybrid') && (
							<>
								<div className="w-full">
									<Input
										id="street"
										name="street"
										handleChange={formik.handleChange}
										value={formik.values.street}
										errorMessage={formik.errors.street}
										type="text"
										placeholder="Event Street"
									/>
								</div>
								<div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-1">
									<Input
										id="city"
										name="city"
										placeholder="Event City"
										type="text"
										handleChange={(e) => {
											e.preventDefault()
											const { value } = e.target
											formik.setFieldValue('city', value)
										}}
										value={formik.values.city}
										errorMessage={formik.errors.city}
									/>
									<Input
										id="state"
										name="state"
										placeholder="Event State"
										type="text"
										handleChange={(e) => {
											e.preventDefault()
											const { value } = e.target
											formik.setFieldValue('state', value)
										}}
										value={formik.values.state}
										errorMessage={formik.errors.state}
									/>
								</div>
								<div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-1">
									<Input
										id="zipcode"
										name="zipcode"
										placeholder="Event Zip Code"
										type="text"
										handleChange={(e) => {
											e.preventDefault()
											const { value } = e.target
											formik.setFieldValue('zipcode', value)
										}}
										value={formik.values.zipcode}
										errorMessage={formik.errors.zipcode}
									/>
									<Input
										id="country"
										name="country"
										placeholder="Event Country"
										type="text"
										handleChange={(e) => {
											e.preventDefault()
											const { value } = e.target
											formik.setFieldValue('country', value)
										}}
										value={formik.values.country}
										errorMessage={formik.errors.country}
									/>
								</div>
							</>
						)}
						<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 gap-2 md:gap-1">
							<div className="col-span-1 z-40">
								<EditModeDatePicker
									errorMessage={formik.errors.date}
									required
                  value={formik.values.date}
									onSelect={(dateObject) => {
                    date.set(dateObject)
										formik.setFieldValue('date', dateObject.date)
                    formik.setFieldValue('jsonDate', dateObject.jsonDate)
                    formik.setFieldValue('unixTimestamp', dateObject.unixTimestamp)
									}}
								/>
							</div>

							<div className="col-span-1">
								<div className="w-full">
									<Dropdown
                    selectedOption={formik.values.selectedOption?.eventType}
                    errorMessage={formik.errors.eventType}
										placeholder="Choose Event Type*"
										options={eventOptions}
										styles={customStyles()}
										onChange={(option) => {
											formik.setFieldValue('eventType', option.value)
                      const selectedOption = formik.values.selectedOption
                      selectedOption['eventType'] = option
                      formik.setFieldValue('selectedOption', selectedOption)
										}}
									/>
								</div>
							</div>
						</div>
						<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-2 md:gap-1">
							<div className="col-span-1">
                <div className="w-full">
                  <Dropdown
                    selectedOption={formik.values.selectedOption?.startTime}
                    errorMessage={formik.errors.startTime}
                    placeholder="Start Time*"
                    options={timeOptions}
                    onChange={(option) => {
                      formik.setFieldValue('startTime', option.value)
                      const selectedOption = formik.values.selectedOption
                      selectedOption['startTime'] = option
                      formik.setFieldValue('selectedOption', selectedOption)
                    }}
                  />
                </div>
							</div>

							<div className="col-span-1">
                <div className="col-span-1">
                  <div className="w-full">
                    <Dropdown
                      selectedOption={formik.values.selectedOption?.endTime}
                      placeholder="End Time"
                      options={timeOptions}
                      onChange={(option) => {
                        formik.setFieldValue('endTime', option.value)
                        const selectedOption = formik.values.selectedOption
                        selectedOption['endTime'] = option
                        formik.setFieldValue('selectedOption', selectedOption)
                      }}
                    />
                  </div>
                </div>
							</div>
						</div>
            { date.get()?.unixTimestamp &&
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 gap-2 md:gap-1">
                <PlacesTypeAhead
                  date={date.get()}
                  placeholder="Event Location*"
                  errorMessage={formik.errors.eventLocation}
                  onSelect={(s) => {
                    formik.setFieldValue('eventLocation', s.label)
                    formik.setFieldValue('locationDetails', s.locationDetails)
                    console.log(s.locationDetails, formik.values.locationDetails)
                  }
                  }
                />
              </div>
            }
						<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-2 md:gap-1">
							<div className="col-span-1">
								<Input
									id="phone"
									name="phone"
									placeholder="Phone*"
									type="text"
									pattern="[0-9]*"
									handleChange={(e) => {
										e.preventDefault()
										const { value } = e.target
										const regex =
											/^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/
										if (regex.test(value.toString())) {
											formik.setFieldValue('phone', value)
										}
									}}
									value={formik.values.phone}
									errorMessage={formik.errors.phone}
								/>
							</div>
							<div className="col-span-1">
								<div className="w-full">
									<Input
										id="email"
										name="email"
										handleChange={formik.handleChange}
										value={formik.values.email}
										errorMessage={formik.errors.email}
										placeholder="Email Address*"
									/>
								</div>
							</div>
						</div>
						<div className="w-full">
							<Input
								id="eventDetails"
								name="eventDetails"
								handleChange={formik.handleChange}
								value={formik.values.eventDetails}
								placeholder="Event Details (Optional)"
								multiline
								rows={5}
							/>
						</div>
					</div>
					<div className="w-full flex items-center justify-center my-4">
						<button
							className={`${
								submitting.get() ? 'bg-orange-200' : 'bg-orange-500'
							} w-10/12 h-12 items-center text-white font-bold py-2 px-4 focus:outline-none focus:shadow-outline`}
							type="submit"
						>
							{submitting.get() ? 'Creating Event...' : 'Create an Event'}
						</button>
					</div>
				</form>
			</Card>
		</>
	)
}
