import PropTypes from "prop-types";
import React from "react";
import DatePicker from "./date-picker";

const EditModeDatePicker = ({readOnly, errorMessage, onSelect, required, value}) => {
  if (readOnly) {
    return (
      <>
        {value}
      </>
    )
  }
  return (
    <div className="w-full">
      <DatePicker
        value={value}
        errorMessage={errorMessage}
        required={required}
        onSelect={onSelect}
      />
    </div>
  )
}

EditModeDatePicker.defaultProps = {
  value: '',
  required: false,
  onSelect() {
  },
  errorMessage: '',
  readOnly: false
}

EditModeDatePicker.propTypes = {
  value: PropTypes.string,
  required: PropTypes.bool,
  onSelect: PropTypes.func,
  errorMessage: PropTypes.string,
  readOnly: PropTypes.bool
}

export default EditModeDatePicker;
