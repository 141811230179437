import React, { useEffect } from 'react'
import Layout from '../layouts/full'
import { Formik, Form, /*Field,*/ useField, ErrorMessage } from 'formik'
import { useState } from '@hookstate/core'
// import {getAuth, signInWithEmailAndPassword} from "firebase/auth";
// import Input from "../components/forms/input/input";
import { object, string } from 'yup'
import { AuthContext } from '../components/auth/auth-provider'
import store from '../store/store'
import { BsEye, BsEyeSlash } from 'react-icons/bs'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useAlert } from 'react-alert'

const Login = () => {
	let navigate = useNavigate()
	let location = useLocation()
	// let { oobCode,mode } = useParams();
	const alert = useAlert()
	const { globalError } = useState(store)
	const loading = useState(false)
	const error = useState('')
	const { signin, handleVerifyEmail } = React.useContext(AuthContext)
	const { code } = globalError.auth.get()

	if (code === 'auth/user-disabled') {
		error.set('Account has been suspended!')
	} else if (code === 'auth/wrong-password') {
		// userInfo.set(values);
		error.set('incorrect password')
	} else if (code === 'auth/user-not-found') {
		error.set('email does not exist in our system!')
	} else if (code === 'Email is Not Valid') {
		error.set('Email is Not Valid')
	} else {
		error.set(code)
	}

	const handleEmailVerification = async (urlQuery) => {
		const actionCode = urlQuery.get('oobCode')
		loading.set(true)
		handleVerifyEmail({ actionCode }, () => {
			loading.set(false)
			alert.show('Your Email has been verified Successfully!', {
				type: 'success',
			})
		})
	}
	const handlePasswordReset = async (urlQuery) => {
		const actionCode = urlQuery.get('oobCode')
		const mode = urlQuery.get('mode')
		loading.set(true)
		navigate('/passwordreset', {
			state: { actionCode: actionCode, mode: mode },
		})
	}
	//TODO://use useParams
	const urlQuery = new URLSearchParams(location.search)

	useEffect(() => {
		const mode_ = urlQuery.get('mode')

		if (mode_ === 'verifyEmail') {
			handleEmailVerification(urlQuery)
		} else if (mode_ === 'resetPassword') {
			handlePasswordReset(urlQuery)
		} else {
			//do nothing
		}
	}, [urlQuery])

	const onSubmit = async (values) => {
		loading.set(true)
		error.set('')
		const { email, password } = values
		signin({ email, password }, () => {
			loading.set(false)
			navigate('/home')
		})
	}
	return (
		<Layout showLoginLink={false}>
			<section className="text-center lg:w-full lg:text-left">
				<div className="h-screen pb-40 mt-0 flex items-center justify-center flex-col ">
					<div className="text-left sm:w-9/12 lg:w-4/12 pb-4">
						<div className="font-bold">Welcome!</div>
						<div className="font-medium">Sign in to continue</div>
					</div>

					<div className="pt-6 sm:w-9/12 lg:w-4/12">
						<Formik
							initialValues={{
								email: '',
								password: '',
							}}
							onSubmit={onSubmit}
							validationSchema={LoginValidation}
						>
							{() => {
								return (
									<Form className="w-full shadow-md rounded px-8 pt-6 pb-8 bg-gray-100">
										<div className="font-medium text-gray-600 pb-4">
											SIGN IN
										</div>
										<Input name="email" placeholder="Email" />
										<Input
											name="password"
											placeholder="Password"
											type="password"
										/>
										{code && (
											<div className="text-red-500 text-xs text-right py-2 px-4">
												email or password is incorrect!
											</div>
										)}
										<div className="flex items-center justify-between">
											<button
												className="bg-orange-500 text-white font-bold py-2 px-4 focus:outline-none focus:shadow-outline"
												type="submit"
											>
												Login
											</button>
										</div>
										<div className="text-xs flex flex-row py-3 justify-between">
											<div>
												<Link
													to="/forgetPassword"
													state={{ backgroundLocation: location }}
												>
													Forgot Password?
												</Link>
											</div>
											<div>
												Create new Account?{' '}
												<span className="text-gray-600 font-bold underline">
													<Link
														to="/signUp"
														state={{ backgroundLocation: location }}
													>
														Sign Up
													</Link>
												</span>
											</div>
										</div>
									</Form>
								)
							}}
						</Formik>
					</div>
				</div>
			</section>
		</Layout>
	)
}

const LoginValidation = object().shape({
	email: string().email('valid email required').required('email required'),
	password: string().required('password required'),
})

const Input = ({ name, label, type, ...props }) => {
	const [field, meta] = useField(name)
	const showPassword = useState(false)
	const dynamicType = showPassword.get() ? 'text' : 'password'
	return (
		<div className="mb-4">
			{/*<label className="block text-gray-700 text-sm font-bold" for={field.name}>*/}
			{/*  {label}*/}
			{/*</label>*/}
			{type === 'password' && (
				<div className="relative">
					<input
						type={dynamicType}
						className={`${
							meta.error && meta.touched ? 'border-red-500' : ''
						} shadow appearance-none rounded w-full px-4 py-3 text-sm bg-white rounded-sm shadow-none border-0 leading-tight focus:outline-none focus:shadow-outline`}
						{...field}
						{...props}
					/>
					<div
						onClick={() => {
							showPassword.set(!showPassword.get())
						}}
						className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
					>
						{showPassword.get() ? <BsEyeSlash /> : <BsEye />}
					</div>
				</div>
			)}
			{type !== 'password' && (
				<input
					className={`${
						meta.error && meta.touched ? 'border-red-500' : ''
					} shadow appearance-none rounded w-full px-4 py-3 text-sm bg-white rounded-sm shadow-none border-0 leading-tight focus:outline-none focus:shadow-outline`}
					{...field}
					{...props}
				/>
			)}
			<ErrorMessage
				name={field.name}
				component="div"
				className="text-red-500 text-xs"
			/>
		</div>
	)
}

export default Login
