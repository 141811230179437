import React from 'react'
import {
	getAuth,
	setPersistence,
	browserSessionPersistence,
	signInWithEmailAndPassword,
	signInAnonymously,
	signOut,
	createUserWithEmailAndPassword,
	updateProfile,
	sendEmailVerification,
	sendPasswordResetEmail,
	confirmPasswordReset,
	verifyPasswordResetCode,
	applyActionCode,
} from 'firebase/auth'

import { getApp } from 'firebase/app'
import { useState } from '@hookstate/core'
import store, { initial } from '../../store/store'
import { Persistence } from '@hookstate/persistence'
import { createUserInfo } from '../../services/users'

let AuthContext = React.createContext(null)

function AuthProvider({ children }) {
	let { user, globalError } = useState(store)
	const auth = getAuth()
	auth.onAuthStateChanged(function (u) {
		if (u && !user.isAuthenticated.get()) {
			user.set({
				displayName: u.displayName,
				email: u.email,
				emailVerified: u.emailVerified,
				isAuthenticated: !!u,
				anon: u.isAnonymous,
				uid: u.uid,
			})
		}
	})
	const localUser = useState(JSON.parse(JSON.stringify(user.get()))) // only need to save user obj to local store
	localUser.attach(Persistence('user'))

	let signin = async ({ email, password }, callback) => {
		try {
			await setPersistence(auth, browserSessionPersistence)
			const response = await signInWithEmailAndPassword(auth, email, password)

			const u = response.user
			user.set({
				displayName: u.displayName,
				email: u.email,
				emailVerified: u.emailVerified,
				isAuthenticated: !!u,
				anon: false,
				uid: u.uid,
			})
			//TODO:add remember me to local storage
			localUser.set({
				displayName: u.displayName,
				email: u.email,
				emailVerified: u.emailVerified,
				isAuthenticated: !!u,
				anon: false,
				uid: u.uid,
			})
			if (callback) {
				callback()
			}
			if (!u.emailVerified) {
				await sendEmailVerification(auth.currentUser)
			}
		} catch (e) {
			globalError.auth.set({ code: e.code })
		}
	}

	let anonsignin = async (onSuccess) => {
		try {
			const auth = getAuth(getApp())
			await setPersistence(auth, browserSessionPersistence)
			const u = await signInAnonymously(auth)
			user.set({
				displayName: '',
				email: '',
				emailVerified: false,
				isAuthenticated: !!u,
				anon: true,
				uid: '',
			})
			localUser.set({ isAuthenticated: !!u, anon: true })
			globalError.auth.set({})
			onSuccess()
		} catch (e) {
			console.error(e.code, e.message)
			globalError.auth.set({ code: e.code })
		}
	}

	let signout = async (callback) => {
		await signOut(auth)
		localUser.set(initial.user)
		localStorage.clear()
		if (callback) {
			callback()
		}
	}

	let signUp = async (
		{
			email,
			password,
			firstName,
			lastName,
			phoneNumber,
			referralCode,
			isAgreement,
		},
		callback,
		onError
	) => {
		try {
			const userCredential = await createUserWithEmailAndPassword(
				auth,
				email,
				password
			)

			const u = userCredential.user
			updateProfile(u, {
				displayName: `${firstName} ${lastName}`,
			})

			await createUserInfo({
				referralCode: referralCode,
				phoneNumber: phoneNumber,
				isAgreement: isAgreement,
			})

			// send email verification
			if (!u.emailVerified) {
				await sendEmailVerification(u)
				//TODO: Test this sendEmailVerification
			}

			user.set({
				displayName: u.displayName,
				email: u.email,
				emailVerified: u.emailVerified,
				isAuthenticated: !!u,
			})

			if (callback) {
				callback()
			}
		} catch (e) {
			console.log('Error:', e)
			globalError.auth.set({ code: e.code })
			if (onError) {
				onError(e.code)
			}
		}
	}

	let resetPassword = async ({ email }, callback) => {
		try {
			await sendPasswordResetEmail(auth, email)
			if (callback) {
				callback()
			}
		} catch (e) {
			globalError.auth.set({ code: e.code })
			// do nothing!!!
		}
	}

	let PasswordResetConfirmation = async ({ code, password }, callback) => {
		try {
			verifyPasswordResetCode(auth, code)
				.then((email) => {
					confirmPasswordReset(auth, code, password)
						.then((resp) => {
							if (callback) {
								callback()
							}
						})
						.catch((error) => {
							// Error occurred during confirmation. The code might have expired or the
							// password is too weak.
							globalError.auth.set({ code: error.code })
						})
				})
				.catch((error) => {
					// Invalid or expired action code. Ask user to try to reset the password
					// again.
					globalError.auth.set({ code: error.code })
				})
		} catch (e) {
			globalError.auth.set({ code: e.code })
			// do nothing!!!
		}
	}
	let handleVerifyEmail = async ({ actionCode, continueUrl }, callback) => {
		try {
			applyActionCode(auth, actionCode)
				.then((resp) => {
					if (callback) {
						callback()
					}
				})
				.catch((error) => {
					// Code is invalid or expired. Ask the user to verify their email address
					// again.
					globalError.auth.set({ code: error.code })
				})
		} catch (e) {
			globalError.auth.set({ code: e.code })
			// do nothing!!!
		}
	}

	let value = {
		signin,
		signout,
		signUp,
		resetPassword,
		PasswordResetConfirmation,
		anonsignin,
		handleVerifyEmail,
	}

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export { AuthProvider, AuthContext }
