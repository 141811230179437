import { getFunctions, httpsCallable } from 'firebase/functions'
import { removeEmptyOrNullValues } from '../utils/service-utils'
import store from '../store/store'
import { none } from '@hookstate/core'
import {retrieveSubscriptions} from "./subscriptions";

export const retrieveEvents = (options) => {
	const retrieveEventsList = httpsCallable(
		getFunctions(),
		'events-retrieveEventsList'
	)
	retrieveEventsList({ options })
		.then((result) => {
      store.events.set(result.data)
      store.event.loading.set(false)
      retrieveSubscriptions(options)
		})
		.catch((error) => {
			// Getting the Error details.
			const code = error?.code
			const message = error?.message
			const details = error?.details
			console.log(code, message, details)
		})
}
export const retrieveSearchableEventsList = (searchTerm, onSuccess) => {
	const retrieveSearchableEventsList = httpsCallable(
		getFunctions(),
		'events-retrieveSearchableEventsList'
	)
	retrieveSearchableEventsList({ searchTerm })
		.then((result) => {
			store.searchableEvents.set(result.data)
			if (onSuccess) {
        onSuccess()
      }
		})
		.catch((error) => {
			// Getting the Error details.
			const code = error?.code
			const message = error?.message
			const details = error?.details
			console.log(code, message, details)
		})
}

export const retrieveEvent = (id, onSuccess) => {
	const data = { eventId: id }
	const retrieveEventById = httpsCallable(
		getFunctions(),
		'events-retrieveEventById'
	)
	retrieveEventById(data)
		.then((result) => {
			if (onSuccess) {
				onSuccess(result.data)
			}
		})
		.catch((error) => {
			// Getting the Error details.
			const code = error.code
			const message = error.message
			const details = error.details
			console.error(code, message, details)
		})
}

export const createEvent = (data, onSuccess, onFailure) => {
	const create = httpsCallable(getFunctions(), 'events-createEvent')
	// remove empty fields with empty strings
	removeEmptyOrNullValues(data)
	create(data)
		.then((result) => {
			// Read result of the Cloud Function.
			/** @type {any} */
			const data = result.data
			store.events.merge([data])
			if (onSuccess) {
				onSuccess(data)
			}
		})
		.catch((error) => {
			// Getting the Error details.
			const code = error.code
			const message = error.message
			const details = error.details
			console.log(code, message, details)
			if (onFailure) {
				onFailure()
			}
		})
}
//updateEvent

export const updateEvent = (data, onSuccess, onFailure) => {
	const update = httpsCallable(getFunctions(), 'events-updateEvent')
	update(data)
		.then((result) => {
			// Read result of the Cloud Function.
			/** @type {any} */
			// const data = result.data;
			const { event, events, onGoingEvents } = store
			events[event.selectedEvent.get()].merge(data)
			if (onSuccess) {
				onSuccess(data)
			}
		})
		.catch((error) => {
			// Getting the Error details.
			const code = error.code
			const message = error.message
			const details = error.details
			console.log(code, message, details)
			if (onFailure) {
				onFailure(error)
			}
		})
}

export const deleteEvent = (data, onSuccess, onFailure) => {
	const update = httpsCallable(getFunctions(), 'events-updateEvent')
	//
	let index = store.event.selectedEvent.get()
	store.event.selectedEvent.set(0)
	store.events[index].set(none)
	update(data)
		.then((result) => {
			if (onSuccess) {
				store.isEventDeleted.set(false)
				onSuccess(result)
			}
		})
		.catch((error) => {
			// Getting the Error details.
			const code = error.code
			const message = error.message
			const details = error.details
			console.log(code, message, details)
			if (onFailure) {
				onFailure()
			}
		})
}
