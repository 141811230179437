import ModalDeprecated from "../components/modal/modal-deprecated";
import Input from "../components/forms/input/input";
import Checkbox from "../components/forms/checkbox/checkbox";
import React from "react";
import {useState} from "@hookstate/core";
import store from "../store/store";

function ConsentModal() {
  const {modal} = useState(store)
  const errorMessage = useState("")
  const allowSubmit = !!modal.data.get()?.name && !!modal.data.get()?.consent

  return <ModalDeprecated
    opened={modal.open.get()}
    allowSubmit={allowSubmit}
    onSubmit={() => {
      if (allowSubmit) {
        const callback = modal.callback.get()
        callback()
        modal.open.set(false)
      } else {
        errorMessage.set(allowSubmit ? "" : "Please enter your name and check the above box.")
      }

    }}
    onClose={() => {
      modal.open.set(false)
    }}>
    <Input
      type="text"
      name="fullname"
      placeholder="Your Full Name"
      onChange={(value) => {
        modal.data.set({...modal.data.get(), name: value})
      }}
      value={modal.data.get()?.name}
      // errorMessage={errorMessage.fullname}
    />
    <Checkbox
      onChange={(value) => {
        modal.data.set({...modal.data.get(), consent: value})
      }}
      name="emailConsent"
      description="I would like to receive emails from DynamoCash"/>
    {!allowSubmit && <div className="text-sm text-red-400">{errorMessage.get()}</div>}
  </ModalDeprecated>;
}

ConsentModal.propTypes = {
};

export default ConsentModal
