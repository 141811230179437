import { useState } from '@hookstate/core'
import React, { useEffect, useRef } from 'react'
import { FiEdit } from 'react-icons/fi'
import { MdDelete } from 'react-icons/md'
import store from '../../store/store'
import Card from '../cards/card'
import { batchSubscribeToEvent } from '../../services/subscriptions'
import EventDetail from './event-detail'
import { useAlert } from 'react-alert'
import { retrieveEvent } from '../../services/events'
import QrcodeImageSlider from '../qrcode-slider/qrcode-slider'
import EmailTagInput from "../forms/dynamic-input/email-tag-input";
import {validateEmail} from "../../utils/form-utils";

export default function EventDetails() {
	const { event, events, user, isEventDeleted } = useState(store)
	const alert = useAlert()
	const { selectedEvent, editMode } = event.get()
	const submittingEmails = useState(false)
  const submittedEmails = useState(false)
	const errors = useState({})
	const eventObject = events[selectedEvent]?.get({ noproxy: true })
  let emailErrorMessage = useState('')

	useEffect(() => {
		retrieveSubscriptionEventDetails()
	}, [eventObject?.displayName])

	useEffect(() => {
		event.selectedEvent.set(selectedEvent)
		retrieveSubscriptionEventDetails()
	}, [selectedEvent])

	const retrieveSubscriptionEventDetails = () => {
		if (eventObject?.displayName && !eventObject.id) {
			retrieveEvent(eventObject.eventId, (data) => {
				if (data && Object.keys(data).length > 0) {
					data['isOwner'] = data.uid === eventObject['createdBy']
				}
				events[selectedEvent].merge(data)
			})
		}
	}

	if (events.length === 0) {
		return <></>
	}

	const bulkSubmit = (emailList, reset) => {
		submittingEmails.set(true)

    if(emailList.includes(user.email.get())) {
      errors['email'].set(true)
      emailErrorMessage.set("You cant invite yourself to your own event.")
    } else if (emailList.length > 0) {
			batchSubscribeToEvent(
				{
					eventId: eventObject.id,
          event: eventObject,
					emailList: [...new Set(emailList)],
				},
				() => {
					submittingEmails.set(false)
					submittedEmails.set(true)
          if (reset) {
            reset()
          }
					alert.show('Invitation Successfully Sent', { type: 'success' })
				},
				(e) => {
					console.log(e)
					alert.show('Unable to send invitation', { type: 'error' })
				}
			)
		}
		submittingEmails.set(false)
	}

	const dynamicInput = (
		<>
      <EmailTagInput
        placeholder="Enter email and press enter"
        error={emailErrorMessage.get()}
        clearError={() => emailErrorMessage.set("")}
        isSubmitting={submittingEmails.get()}
        onSubmit={bulkSubmit}
        validate={validateEmail}
      />
		</>
	)
	return (
		<div className="min-w-0 text-gray-500 z-40">
			<Card>
				{/* isEventDelete */}

				<div className="text-left text-orange-400 capitalize font-bold text-lg w-4/5">
					{editMode
						? 'Edit Event'
						: (`${eventObject?.displayName || eventObject?.eventName} ${eventObject?.eventType}`)}
				</div>
				<div
					className={`${
						editMode ? 'hidden' : ''
					} grid grid-cols-2 gap-2 absolute top-5 right-5 text-black`}
				>
					<div
						className="cursor-pointer hover:bg-gray-200"
						onClick={() => {
              console.log("see me!")
              event.editMode.set(true)
              console.log("see me!", event.get().eventMode)
            }}
					>
						<FiEdit size={28} />
					</div>
					{eventObject?.uid === user.uid.get() && (
						<div
							className={`${
								eventObject?.isOwner === false ? 'hidden' : ''
							} cursor-pointer hover:bg-gray-200`}
							onClick={() => {
								isEventDeleted.set(true)
							}}
						>
							<MdDelete size={28} />
						</div>
					)}
				</div>
				<div className="grid grid-cols-1 gap-2 ">
					<EventDetail
						editMode={editMode}
						event={eventObject}
						onCancel={() => {
							event.editMode.set(false)
						}}
						onUpdate={() => {
							event.editMode.set(false)
						}}
						user={user}
					>
						<QrcodeImageSlider />
					</EventDetail>
					{eventObject?.uid === user.uid.get() &&
					(eventObject.eventAccess === 'public' ||
					eventObject.status !== 'inactive') ? (
						<div className={`${editMode ? 'hidden' : ''}`}>
							<div className="font-bold text-orange-400">Invite Guest</div>
							{dynamicInput}
						</div>
					) : null}
				</div>
			</Card>
		</div>
	)
}
