import React, {useEffect} from "react";
import {retrieveEvent, updateEvent} from "../../services/events";
import {useState} from "@hookstate/core";
import store from "../../store/store";
import {useNavigate, useParams} from "react-router-dom";
import {dateWithTimeZone, getTime} from "../../utils/date-utils";
import GuestList from "../../components/guest-list";
import InviteGuestModal from '../../components/modal/invite-guest-modal'
import { useCountdown, useTimer } from "../../utils/count-down-util";
import CountDown from "../../components/countdown";
import ExpiredNotice from "../../components/expired-note";
import Loader from "../../components/loader";
import ConfirmationModal from "../../components/modal/confirmation-modal";
import { fetchEventByUserEmail, fetchParticipant, updateSubscription, updateSubscriptionInRealTimeDatabase } from "../../services/subscriptions";
import { eventStatus, modalID } from "../../constants";
import DisplayNameModal from "../../components/modal/change-displayname-modal";
import InLobbyNote from "../../components/InLobbyNote";

const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };

const OngoingEvent = () => {
  const {eventId} = useParams()
  const navigate = useNavigate()
  const {onGoingEvents, modal, alert, isLoading, user, participants, eventDetail, enableShare, sharedAll} = useState(store)
  const userEmail = user.email.get();
	const modalData = modal.data.get();
	const toggleModal = modal.open.get();
  const eventObject = eventDetail.get();
  const eventParticipants = participants.get()
  const eventResponse = onGoingEvents[eventId].get()
  const [event, setEvent] = React.useState(eventResponse)
  const [changeNameSet, setChangeName] = React.useState(false)
  const [loading, setIsLoading] = React.useState(true)
  const [showTotalContribution, setShowTotalContribution] = React.useState(false)
  const eventDateArray = event?.date.split('-')

 const time = getTime(event?.startTime)
 const date  = new Date(event?.date)
 const newDate =  dateWithTimeZone(event?.startTimeZone, date.getFullYear(), date.getMonth()+1, date.getDate(), time.hour, time.minute)
  const [days, hours, minutes, seconds] = useCountdown(newDate);
  let eventDate
  if (eventDateArray) {
    eventDate = dateWithTimeZone(event?.startTimeZone, eventDateArray[0], eventDateArray[1], eventDateArray[2])
  }

  useEffect(() => {
    retrieveEvent(eventId, (data) => {
      onGoingEvents.merge({[data.id]: data})
      sharedAll.set(data?.sharedAll)
      setIsLoading(false)
    })

  }, [])

  useEffect(()=>{

    if (eventObject.status === eventStatus.ENDED) {
      alert.set({show: true, data: {message: "Event ended!", type: 'success'}})
      navigate("/home")
    }

    if (!changeNameSet) {

      if (eventObject.status === eventStatus.JOINED ||
        eventObject.status === eventStatus.INLOBBY &&
        !eventObject?.displayName  ) {
          setChangeName(true)
        modal.set({open: true, data:{id: modalID.CHANGE_DISPLAY_NAME, title: "Change Display Name", message:`Enter name you want to display for this event`, displayName: eventObject?.guestDisplayName}})
      }

    }


  },[eventObject])

  useEffect(() => {

    fetchParticipant(event?.id)
    fetchEventByUserEmail(event?.id, userEmail)

  }, [event]);

  useEffect(() => {
    setEvent(eventResponse)
  }, [loading])

  const closeModal = () =>{
    modal.set({open: false})
  }

  const endEvent = () => {
    modal.set({open: false})
    isLoading.set(true)
    updateEvent({
      id: event.id,
      eventName: event.eventName,
      status: eventStatus.ENDED,
      hostEndedAt: new Date(),
      uid: event.uid
    }, onEndEventSuccess, onEndEventFailure);

  }

  const onEndEventSuccess = (res) => {
    onGoingEvents[eventId].status.set("ended")
    isLoading.set(false)
    alert.set({show: true, data: {message: "Event ended, an event report will be sent to your email", type: 'success'}})
    navigate("/home")

  }

  const onEndEventFailure = (res) => {
    isLoading.set(false)
    alert.set({show: true, data: {message: "Event failed to be end", type: 'error'}})

  }

  const leaveEvent = () => {
    modal.set({open: false})
    isLoading.set(true)
    updateSubscription({
		eventId: event.id,
        displayName: event.eventName,
        status: eventStatus.LEFT_EVENT,
        userLeftAt: new Date(),
      }, onLeaveEventSuccess, onLeaveEventFailure);
  }

  const share = (participant) => {

    const share = participant?.share
    const email = participant?.email
    updateSubscriptionInRealTimeDatabase(event.id,email, {share: !share}).then(() => {
      // Data saved successfully!
      isLoading.set(false)
      enableShare.set(false)
      })
      .catch((error) => {
      // The write failed...
      isLoading.set(false)
      });
    
  }

  const onLeaveEventSuccess = (res) => {

    onGoingEvents[eventId].status.set("out")
    isLoading.set(false)
    alert.set({show: true, data: {message: "You left this event", type: 'success'}})
    navigate("/home");

  }
  const onLeaveEventFailure = (res) => {

    isLoading.set(false)
    alert.set({show: true, data: {message: "Event failed to be ended", type: 'error'}})
  }

  let guestListHtml
  // check if event date is today
  // if (eventDate && eventDate.setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0)) {
    guestListHtml =  (
      <GuestList participants={eventParticipants}/>
    )
  // }

  let locationHtml = <div>{event?.meetingUrl ? <a href={event.meetingUrl}>Online (click to join)</a> : `Online Event ${event?.isHost ? '(Please provide a meeting url)': null} `}</div>
  if (event?.eventMode === 'physical') {
    locationHtml = <div>{event.city}, {event.state}, {event.country}</div>
  }

  if (loading) {
    return <Loader/>
  }

  return (
    <div className="bg-white md:flex">

      {
        modalData?.id ==="invite_guest" &&  <InviteGuestModal
        eventId={eventId}
        opened={modal?.open.get()}
        text={`Invite guests to this event`}
        title="Invite new guests"
        actionStatus={false}
        handleDelete={() => {}}
      />
      }

     {
       modalData?.id === modalID.CHANGE_DISPLAY_NAME && <DisplayNameModal
        eventId={eventId}
        opened={modal?.open.get()}
        displayName={modalData?.displayName}
        text={`Enter name to be display for this event`}
        title="Change Display Name"
        actionStatus={false}
        handleDelete={() => {}}
      />
      }

      <div>
        <div className="pt-10 px-4 border-r border-slate-300 md:px-8 md:flex" >
          <div>
            <p className="inline-flex"><p className="text-2xl md:text-4xl font-small text-lightblack mb-4 capitalize">{event?.eventName} {event?.eventType}</p> <super className='ml-2 text-gray-400 text-sm'> {event?.eventAccess}</super></p>
            <div className="text-1xl md:text-2xl font-small text-gray-500 ">{locationHtml}</div>
            <div className=" text-sm md:text-1xl font-small text-gray-500 my-7">{eventDate?.toLocaleDateString("en-US", dateOptions)}</div>
            <div className="flex justify-start gap-2 my-5 md:gap-10 md:my-10">
              <div className="flex flex-1">
                <input className="accent-orange-500 flex-1  h-4 w-4" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option"   checked={event?.eventMode==="virtual"}/>
                <label className="text-gray-500 text-sm" for="inlineRadio20">Virtual Event</label>
              </div>
              <div className="flex flex-1">
                <input className="accent-orange-500 flex-1 h-4 w-4" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1"  checked={event?.eventMode==="physical"}/>
                <label className="text-gray-500 text-sm" for="inlineRadio1">Physical Event</label>
              </div>
          </div>
          </div>

          <div className="ml-auto">
            {(days + hours + minutes + seconds) <= 0 && !event?.hostStartedAt?
              <ExpiredNotice />:
              event?.hostStartedAt? null:
              <CountDown days={days} hours={hours} minute={minutes} seconds={seconds} />
              }
           </div>
        </div>
        <img src={require("../../assets/img/car_auction.png")} alt="event_image"/>

      </div>

      <div className="md:w-3/6 flex flex-col">
       {
        event?.isHost || eventObject.status === eventStatus.JOINED?
        <>
        {guestListHtml}

        {
          event?.isHost || eventObject?.share ?
          <div className="flex flex-col justify-center p-10 mt-auto  md:h-1/4">

            <p className="text-gray-500 text-sm md:text-1xl mb-2">
              Total Contributions 
              <button className="p-1 font-normal rounded-none ml-2" onClick={()=>setShowTotalContribution(!showTotalContribution)}>
                {!showTotalContribution?" show":" hide"}</button>
              </p>
            <input type={`${showTotalContribution?"text":"password"}`}  disabled={true} value={"$ 2, 382.30"}  className="rounded bg-gray-200 h-20 w-full outline  outline-1 outline-gray-400 p-5 text-1xl md:text-2xl text-gray-400" />

              {/* <p className="text-1xl md:text-2xl text-gray-400 "></p>

          </input> */}

        </div>:null

        }
        </>:
        eventObject.status === eventStatus.INLOBBY?
        <div className="flex flex-1 flex-col justify-center items-center  md:h-1/4">
         <InLobbyNote/>
        </div>
        : null

       }


      </div>
      {
        modalData?.id === modalID.LEAVE_EVENT
       && <ConfirmationModal
				title={modalData?.title}
				text={modalData?.message}
				opened={toggleModal}
        onClose={closeModal}
				disableAction={closeModal}
        enableAction={leaveEvent}
				 />
      }

      {
       modalData?.id === modalID.END_EVENT
       && <ConfirmationModal
				title={modalData?.title}
				text={modalData?.message}
				opened={toggleModal}
        onClose={closeModal}
				disableAction={closeModal}
        enableAction={endEvent}
				 />
      }
    {
       modalData?.id === modalID.SHARE
       && <ConfirmationModal
				title={modalData?.title}
				text={modalData?.message}
				opened={toggleModal}
        onClose={closeModal}
				disableAction={closeModal}
        enableAction={()=>share(modalData?.participant)}
				 />
      }



  </div>

  )
}


export default OngoingEvent
