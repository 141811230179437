import React from "react";
import Modal from "./modal";
import {GrClose} from "react-icons/gr";
import Button from "../forms/button/button";
import {useNavigate} from "react-router-dom";
import {BsCheckCircleFill} from "react-icons/bs";
import {ImWarning} from "react-icons/im";

function ActionModal({opened, text, title, actionStatus, actionBtn}) {
  const navigate = useNavigate();
  const actionButtons =
    <div className="control flex justify-between">
      
      <Button
        onClick={() => {
          actionBtn()
        }}
        label="Ok"
      />
     
    </div>

  const header = (
    <div className="p-6 w-full flex items-center justify-center text-5xl bg-orange-400 text-white">
      {actionStatus ? <BsCheckCircleFill/> : <ImWarning/>}
    </div>
  )
  return <Modal
    header={header}
    actionButtons={actionButtons}
    opened={opened}
    icon={<GrClose/>}>
    <div className="grid grid-columns-1 md:grid-rows-1 gap-3 text-center">
      <div className="bg-white w-full font-bold text-orange-400 text-3xl">{title}</div>
      <div className="text-gray-400">{text}</div>
    </div>
  </Modal>;
}

ActionModal.propTypes = {};

ActionModal.defaultProps = {
  title: "",
  opened: false,
  onClose() {
  },
  actionBtn(){},
  text: "",
  actionStatus: false
}

export default ActionModal
