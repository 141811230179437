// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  staging: {
    apiKey: "AIzaSyB2Avi26GoewIjnNZjsl-GLl3llLz023to",
    authDomain: "dynamocash-staging.firebaseapp.com",
    databaseURL: "https://dynamocash-staging-default-rtdb.firebaseio.com",
    projectId: "dynamocash-staging",
    storageBucket: "dynamocash-assets-staging",
    messagingSenderId: "1076649933699",
    appId: "1:1076649933699:web:92b1cc451ff92233a214b6",
    measurementId: "G-NXP1CJ46SP"
  },
  prod: {
    apiKey: "AIzaSyAo2ihr3fkOHHe5jfAj2MFIqL0dDLxLf3Y",
    authDomain: "dynamocash-e9f89.firebaseapp.com",
    projectId: "dynamocash-e9f89",
    storageBucket: "dynamocash-assets",
    messagingSenderId: "172731555428",
    appId: "1:172731555428:web:99e290f689b54b82ee8971",
    measurementId: "G-1B5DERLFY5",
  },
};

export default firebaseConfig;
